import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useOnScreen } from "./../hooks/onScreen";
import { uniqueId } from "lodash";
import { useRef } from "react";

import HeadText from "../components/HeadText";
import { gallery } from "../data/images";
import { whyUs } from "../constants/stringConst";
import styles from "./WhyUs.module.scss";
import { useTranslation } from "react-i18next";

const WhyUs = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_WhyUs");
    }
  }, [isVisible]);

  return (
    <div ref={ref} className={styles.cardBoard} id="aboutus">
      <HeadText topText={t(whyUs.topMessage)} bottomText={t(whyUs.bottomMessage)} />

      <div className={styles.answerText}>
        <p className={styles.responsiveText}>{t(whyUs.bigAnswer)}</p>
      </div>
      <div className={styles.imageWrapper}>
        <img
          className={styles.imageContainer}
          src={gallery.bathChamp}
          alt="Jacuzii cu șampanie  din Romantic Studio Apartament"
        />
      </div>

      <div className={styles.facitiliesContainer}>
        {Object.values(gallery.facilitiesPack).map((item, index) => (
          <div key={uniqueId()} className={styles.roundCircle}>
            <img className={styles.thumbPic} src={item} />
            <p className={styles.popText}>{t(Object.values(whyUs.facitiliesTextHover)[index])}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
