import React, { useEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import MenuMobile from "./../components/MenuMobile";

import styles from "./Navbar.module.scss";
import { componentStrings } from "../data/strings";
import images from "./../data/images";
import { navMenu } from "./../constants/stringConst";

const Navbar = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const [burgerOpen, setBurgerOpen] = useState<boolean>(false);

  const controlNavbar = () => {
    if (window) {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const sendAnalyticsEvent = (eventName: string) => {
    ReactGA.event(`user_tabSwitch_${eventName}`);
  };

  return (
    <>
      <div
        className={styles.navbarContainer}
        style={
          burgerOpen
            ? null
            : show
            ? {
                transition: "transform 0.5s",
                transform: show ? `translate(${0}px, ${0}px)` : `translate(${0}px, ${-64}px)`
              }
            : {
                transition: "transform 0.3s",
                transform: show ? `translate(${0}px, ${0}px)` : `translate(${0}px, ${-64}px)`
              }
        }>
        <MenuMobile burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
        <ul className={styles.ulMenuEnd}>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.aboutUs.link))}
            className={styles.liItem}
          >
            <NavHashLink className={styles.HashLinkStyle} to={`/#${t(navMenu.items.home.link)}`}>
              {t(navMenu.items.home.text)}
            </NavHashLink>
          </li>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.reviews.link))}
            className={styles.liItem}
          >
            <NavHashLink className={styles.HashLinkStyle} to={`/#${t(navMenu.items.reviews.link)}`}>
              {t(navMenu.items.reviews.text)}
            </NavHashLink>
          </li>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.gallery.link))}
            className={styles.liItem}
          >
            <NavHashLink className={styles.HashLinkStyle} to={`/#${t(navMenu.items.gallery.link)}`}>
              {t(navMenu.items.gallery.text)}
            </NavHashLink>
          </li>
        </ul>
        <div className={styles.middleNoUl}>
          <NavHashLink to={componentStrings.navMenu.aboutus.link}>
            <img className={styles.navLogo} src={images.logoFooter} />
          </NavHashLink>
        </div>
        <ul className={styles.ulMenuStart}>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.offers.link))}
            className={styles.liItem}
          >
            <NavHashLink className={styles.HashLinkStyle} to={`/#${t(navMenu.items.offers.link)}`}>
              {t(navMenu.items.offers.text)}
            </NavHashLink>
          </li>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.bookings.link))}
            className={styles.liItem}
          >
            <NavHashLink
              className={styles.HashLinkStyle}
              to={`/#${t(navMenu.items.bookings.link)}`}
            >
              {t(navMenu.items.bookings.text)}
            </NavHashLink>
          </li>
          <li
            onClick={sendAnalyticsEvent.bind(this, t(navMenu.items.contactUs.link))}
            className={styles.liItem}
          >
            <NavHashLink
              className={styles.HashLinkStyle}
              to={`/#${t(navMenu.items.contactUs.link)}`}
            >
              {t(navMenu.items.contactUs.text)}
            </NavHashLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
