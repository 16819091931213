import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import StarDisplayer from "../components/StarDisplayer";
import Carousel, { CarouselItem } from "../components/Carousel";
import { ReviewsConst } from "../constants/stringConst";
import { useOnScreen } from "../hooks/onScreen";

import { reviewArrayDesktop, reviewArrayMobile } from "../data/arrays";
import styles from "./Reviews.module.scss";


const Reviews = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_Reviews");
    }
  }, [isVisible]);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const getInitials = useCallback((text: string) => {
    return text.replace(/[^A-Z]+/g, "");
  }, []);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <div ref={ref} id="reviews" className={styles.reviewsSection}>
      <div className={styles.questionsBlock}>
        <h3 className={styles.bookTextTop}>{t(ReviewsConst.topMessage)}</h3>
        <h3 className={styles.bookTextDown}>{t(ReviewsConst.bottomMessage)}</h3>
      </div>
      {screenWidth < 767 ? (
        <Carousel inactiveStyle={styles.roundButton} activeStyle={styles.roundButtonActive}>
          {Object.values(reviewArrayMobile).map((item, index) => {
            return (
              <CarouselItem key={index} width={"100%"}>
                <div className={styles.reviewsArea}>
                  <div>
                    {item[0].map((item, index) => {
                      return (
                        <div key={index} className={styles.cardReview}>
                          <div className={styles.imageAuthor}>
                            <span className={styles.authorInitials}>
                              {getInitials(item.author)}
                            </span>
                          </div>
                          <div className={styles.commentReview}>
                            <div className={styles.name}>{item.author}</div>
                            <div className={styles.comment}>{item.textReview}</div>
                            <div className={styles.starsReview}>
                              <div>
                                <StarDisplayer starScore={Number(item.stars)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </Carousel>
      ) : (
        <Carousel inactiveStyle={styles.roundButton} activeStyle={styles.roundButtonActive}>
          {Object.values(reviewArrayDesktop).map((item, index) => {
            return (
              <CarouselItem key={index} width={"100%"}>
                <div className={styles.reviewsArea}>
                  <div>
                    {item[0].map((item, index) => {
                      return (
                        <div key={index} className={styles.cardReview}>
                          <div className={styles.imageAuthor}>
                            <span className={styles.authorInitials}>
                              {getInitials(item.author)}
                            </span>
                          </div>
                          <div className={styles.commentReview}>
                            <div className={styles.name}>{item.author}</div>
                            <div className={styles.comment}>{item.textReview}</div>
                            <div className={styles.starsReview}>
                              <div>
                                <StarDisplayer starScore={Number(item.stars)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {item[1].map((item, index) => {
                      return (
                        <div key={index} className={styles.cardReview}>
                          <div className={styles.imageAuthor}>
                            <span className={styles.authorInitials}>
                              {getInitials(item.author)}
                            </span>
                          </div>
                          <div className={styles.commentReview}>
                            <div className={styles.name}>{item.author}</div>
                            <div className={styles.comment}>{item.textReview}</div>
                            <div className={styles.starsReview}>
                              <div>
                                <StarDisplayer starScore={Number(item.stars)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default Reviews;
