/* eslint-disable */
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import app from "../firebase";
import _ from "lodash";
import moment from "moment";
import { DayRange, DisabledDays } from "./../utils/interfaceExport/CalendarInterface";

const currentYear = new Date().getFullYear();
const nextYear = currentYear + 1;

const base = getFirestore(app);
export function getDates(startDate: string, stopDate: string) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate1 = moment(stopDate);
  while (currentDate <= stopDate1) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}
export const verifyDates = async () => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const currentYearDatesArray: string[] = [];
  const nextYearDatesArray: string[] = [];
  const currentYearArray = doc(base, "reservationDates", currentYear.toString());
  const nextYearArray = doc(base, "reservationDates", nextYear.toString());

  const currentYearData = await getDoc(currentYearArray);
  const nextYearData = await getDoc(nextYearArray);

  const currentYearDates = currentYearData.data();
  const nextYearDates = nextYearData.data();

  if (currentYearDates && nextYearDates) {
    for (let i = 1; i <= 12; i++) {
      if (currentYearDates[i])
        currentYearDates[i].forEach((element: string | number) => {
          const mounth = i <= 9 ? "0" + i : i;
          const day = element <= 9 ? "0" + element : element;
          currentYearDatesArray.push(currentYear + "-" + mounth + "-" + day);
        });
      if (nextYearDates[i])
        nextYearDates[i].forEach((element: string | number) => {
          const mounth = i <= 9 ? "0" + i : i;
          const day = element <= 9 ? "0" + element : element;
          nextYearDatesArray.push(nextYear + "-" + mounth + "-" + day);
        });
    }
  }

  return { currentYearDates, nextYearDates, currentYearDatesArray, nextYearDatesArray };
};

export const reserveDate = async (
  name: string,
  email: string,
  phoneNumber: string,
  details: string,
  checkInDates: DayRange
) => {
  const startDate =
    checkInDates.from?.year + "-" + checkInDates.from?.month + "-" + checkInDates.from?.day;
  const endDate = checkInDates.to?.year + "-" + checkInDates.to?.month + "-" + checkInDates.to?.day;
  const diffInMs = new Date(endDate).valueOf() - new Date(startDate).valueOf();
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const getDatesBetweenDates = (startDate: string | Date, endDate: Date) => {
    let dates: Date[] = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };

  const daysDateArray = getDatesBetweenDates(new Date(startDate), new Date(endDate));

  const datesArray: string[] = [];

  daysDateArray.forEach((item: Date) =>
    datesArray.push(item.getFullYear() + " " + (item.getMonth() + 1) + " " + item.getDate())
  );

  const datesByYear = _.groupBy(datesArray, (item: string) => item.split(" ")[0]);
  const datesByMonth: {
    [key: string]: {
      [subkey: string]: string[];
    };
  } = {};
  Object.keys(datesByYear).map(
    (key) => (datesByMonth[key] = _.groupBy(datesByYear[key], (doc: string) => doc.split(" ")[1]))
  );

  Object.keys(datesByMonth).map(async (key) => {
    Object.keys(datesByMonth[key]).map((subkey) => {
      const days = datesByMonth[key][subkey].map((item: string) => item.split(" ")[2]);
      datesByMonth[key][subkey] = days;
    });
    const year = key;
    const dates = await getDoc(doc(base, "reservationDates", year));
    const datesData = dates.data();
    function arrayUnique(array: string[]) {
      const a = array.concat();
      for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }
      return a;
    }
    const mergedDates = _.mergeWith(
      dates.data(),
      datesByMonth[year],
      (a: string[], b: string[]) => {
        if (a && b) return arrayUnique(a.concat(b));
        else if (a) return a;
        else return b;
      }
    );

    const verifyDatas = () => {
      if (datesData)
        for (let i = 1; i <= 12; i++)
          if (datesData[i] && datesByMonth[year][i]) {
            if (datesData[i].some((day: number) => datesByMonth[year][i].includes(day.toString())))
              return false;
          }
      return true;
    };
    if (verifyDatas()) {
      await updateDoc(doc(base, "reservationDates", year), mergedDates);
      await setDoc(doc(base, "reservations", name + " - " + startDate), {
        fullName: name,
        email: email,
        phoneNumber: phoneNumber,
        details: details,
        checkInDates: startDate + " - " + endDate,
        reservationDays: diffInDays
      });
    } else {
      alert("At least one of your choosen days is already reserved. Please refresh and try again!");
    }
  });
};

export const manualReserveDate = async (checkInDates: DayRange) => {
  const startDate =
    checkInDates.from?.year + "-" + checkInDates.from?.month + "-" + checkInDates.from?.day;
  const endDate = checkInDates.to?.year + "-" + checkInDates.to?.month + "-" + checkInDates.to?.day;

  const getDatesBetweenDates = (startDate: string | number | Date, endDate: Date) => {
    let dates: Date[] = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };

  const daysDateArray = getDatesBetweenDates(new Date(startDate), new Date(endDate));

  const datesArray: string[] = [];

  daysDateArray.forEach((item: Date) =>
    datesArray.push(item.getFullYear() + " " + (item.getMonth() + 1) + " " + item.getDate())
  );

  const datesByYear = _.groupBy(datesArray, (item: string) => item.split(" ")[0]);
  const datesByMonth: {
    [key: string]: {
      [subkey: string]: string[];
    };
  } = {};
  Object.keys(datesByYear).map(
    (key) => (datesByMonth[key] = _.groupBy(datesByYear[key], (doc: string) => doc.split(" ")[1]))
  );

  Object.keys(datesByMonth).map(async (key) => {
    Object.keys(datesByMonth[key]).map((subkey) => {
      const days = datesByMonth[key][subkey].map((item: string) => item.split(" ")[2]);
      datesByMonth[key][subkey] = days;
    });
    const year = key;
    const dates = await getDoc(doc(base, "reservationDates", year));
    const datesData = dates.data();

    function arrayUnique(array: string[]) {
      const a = array.concat();
      for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }
      return a;
    }
    const mergedDates = _.mergeWith(
      dates.data(),
      datesByMonth[year],
      (a: string[], b: string[]) => {
        if (a && b) return arrayUnique(a.concat(b));
        else if (a) return a;
        else return b;
      }
    );

    const verifyDatas = () => {
      if (datesData)
        for (let i = 1; i <= 12; i++)
          if (datesData[i] && datesByMonth[year][i]) {
            if (datesData[i].some((day: number) => datesByMonth[year][i].includes(day.toString())))
              return false;
          }
      return true;
    };
    if (verifyDatas()) {
      await updateDoc(doc(base, "reservationDates", year), mergedDates);
    } else {
      alert("At least one of your choosen days is already reserved. Please refresh and try again!");
    }
  });
};

export const getDisabledDays = async (setDisabledDays: any) => {
  const allDates = await verifyDates();

  const nextYearDates = allDates.nextYearDates;
  const currentYearDates = allDates.currentYearDates;

  if (currentYearDates)
    for (let i = 1; i <= 12; i++) {
      currentYearDates[i]?.forEach((day: number) =>
        setDisabledDays((prevState: DisabledDays[]) => {
          let temp = [...prevState];
          temp.push({ year: currentYear, month: i, day: Number(day) });
          return temp;
        })
      );
    }

  if (nextYearDates)
    for (let i = 1; i <= 12; i++) {
      nextYearDates[i]?.forEach((day: number) =>
        setDisabledDays((prevState: DisabledDays[]) => {
          let temp = [...prevState];
          temp.push({ year: nextYear, month: i, day: Number(day) });
          return temp;
        })
      );
    }
};

export const verifyCheckInDays = async () => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const currentYearDatesArray: string[] = [];
  const nextYearDatesArray: string[] = [];
  const currentYearArray = doc(base, "checkInDates", currentYear.toString());
  const nextYearArray = doc(base, "checkInDates", nextYear.toString());

  const currentYearData = await getDoc(currentYearArray);
  const nextYearData = await getDoc(nextYearArray);
  const currentYearDates = currentYearData.data();
  const nextYearDates = nextYearData.data();
  for (let i = 1; i <= 12; i++) {
    if (currentYearDates)
      if (currentYearDates[i])
        currentYearDates[i].forEach((element: string | number) => {
          const mounth = i <= 9 ? "0" + i : i;
          const day = element <= 9 ? "0" + element : element;
          currentYearDatesArray.push(currentYear + "-" + mounth + "-" + day);
        });
    if (nextYearDates)
      if (nextYearDates[i])
        nextYearDates[i].forEach((element: string | number) => {
          const mounth = i <= 9 ? "0" + i : i;
          const day = element <= 9 ? "0" + element : element;
          nextYearDatesArray.push(nextYear + "-" + mounth + "-" + day);
        });
  }
  return { currentYearDates, nextYearDates, currentYearDatesArray, nextYearDatesArray };
};

export const getCheckInDays = async (
  setCheckInDays: (arg0: { (prevState: any): any[]; (prevState: any): any[] }) => any
) => {
  const allDates = await verifyCheckInDays();

  const nextYearDates = allDates.nextYearDates;
  const currentYearDates = allDates.currentYearDates;

  if (currentYearDates)
    for (let i = 1; i <= 12; i++) {
      currentYearDates[i]?.forEach((day: number) =>
        setCheckInDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: currentYear, month: i, day: day });
          return temp;
        })
      );
    }

  if (nextYearDates)
    for (let i = 1; i <= 12; i++) {
      nextYearDates[i]?.forEach((day: number) =>
        setCheckInDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: nextYear, month: i, day: day });
          return temp;
        })
      );
    }
};

export const manualDeleteDate = async (checkInDates: DayRange) => {
  const startDate =
    checkInDates.from?.year + "-" + checkInDates.from?.month + "-" + checkInDates.from?.day;
  const endDate = checkInDates.to?.year + "-" + checkInDates.to?.month + "-" + checkInDates.to?.day;

  const getDatesBetweenDates = (startDate: string | number | Date, endDate: Date) => {
    let dates: Date[] = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };

  const daysDateArray = getDatesBetweenDates(new Date(startDate), new Date(endDate));

  const datesArray: string[] = [];

  daysDateArray.forEach((item: Date) =>
    datesArray.push(item.getFullYear() + " " + (item.getMonth() + 1) + " " + item.getDate())
  );

  const datesByYear = _.groupBy(datesArray, (item: string) => item.split(" ")[0]);
  const datesByMonth: {
    [key: string]: {
      [subkey: string]: string[];
    };
  } = {};
  Object.keys(datesByYear).map(
    (key) => (datesByMonth[key] = _.groupBy(datesByYear[key], (doc: string) => doc.split(" ")[1]))
  );

  Object.keys(datesByMonth).map(async (key) => {
    Object.keys(datesByMonth[key]).map((subkey) => {
      const days = datesByMonth[key][subkey].map((item: string) => item.split(" ")[2]);
      datesByMonth[key][subkey] = days;
    });
    const year = key;
    const dates = await getDoc(doc(base, "reservationDates", year));
    const datesData = dates.data();

    let deletedDates: number[] = [];

    for (let i = 1; i <= 12; i++) {
      const tempDeletedDates: number[] = [];
      if (datesData && datesByMonth[year][i]) {
        datesData[i].map((item: number) =>
          !datesByMonth[year][i].includes(item.toString()) ? tempDeletedDates.push(item) : null
        );
      }
      deletedDates = tempDeletedDates;
      await updateDoc(doc(base, "reservationDates", year), { [i]: deletedDates });
    }
  });
};

export const addEmailToList = async (email: string) => {
  await setDoc(doc(base, "emailsForNewsLetter", email), { email });
};

export const verifyIfSubscribed = async () => {
  const users: any[] = [];
  const data = await (await getDocs(collection(base, "newsletterSubscribers"))).forEach(doc => users.push(doc.data().email));
  return users;
};
