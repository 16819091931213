import React from "react";
import { uniqueId } from "lodash";
import images from "./../data/images";
import styles from "./StarDisplayer.module.scss";

interface StarsProps {
  starScore: number;
}

const StarDisplayer = ({ starScore }: StarsProps) => {
  return (
    <div className={styles.starVisualizer}>
      {Array.from({ length: starScore }, () => {
        return (
          <img key={uniqueId()} className={styles.starIcon} src={images.reviewsIcons.fullstar} />
        );
      })}
    </div>
  );
};

export default StarDisplayer;
