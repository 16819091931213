import React, { useCallback, useEffect, useState } from "react";
import {
  getCheckInDays,
  getDisabledDays,
  manualDeleteDate,
  manualReserveDate
} from "../functions/firebase";
import { DisabledDays } from "../utils/interfaceExport/CalendarInterface";
import { Calendar, Day, DayRange } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import styles from "./ManualReservation.module.scss";

interface CustomisedDays {
  year: number;
  month: number;
  day: number;
  className: string;
}

const ManualReservation = () => {
  const [dates, setDates] = useState<DayRange>({
    from: null,
    to: null
  });
  const [deletedDates, setDeletedDates] = useState<DayRange>({
    from: null,
    to: null
  });
  const [showAddBox, setShowAddBox] = useState<boolean>(false);
  const [showDeleteBox, setShowDeleteBox] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [disabledDays, setDisabledDays] = useState<DisabledDays[]>([]);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [checkInDays, setCheckInDays] = useState<Day[]>([]);
  const [checkInDaysCustomised, setCheckInDaysCustomised] = useState<CustomisedDays[]>([]);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
  }, []);

  const currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };

  const reserveDates = async () => {
    await manualReserveDate(dates);
    alert("Datele rezervate au fost adaugate!");
    setDates({
      from: null,
      to: null
    });
    setShowAddBox(false);
    await getDisabledDays(setDisabledDays);
  };

  const deleteDates = async () => {
    await manualDeleteDate(deletedDates);
    alert("Datele rezervarilor au fost sterse cu succes!");
    setDeletedDates({
      from: null,
      to: null
    });
    setRefresh((prevState) => !prevState);
    setShowDeleteBox(false);
    await getDisabledDays(setDisabledDays);
  };

  useEffect(() => {
    if (screenWidth < 576) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  const getDatesBetweenDates = useCallback(() => {
    const startDate = dates.from?.year + "/" + dates.from?.month + "/" + dates.from?.day;
    const endDate = dates.to?.year + "/" + dates.to?.month + "/" + dates.to?.day;
    /* eslint-disable-next-line */
    let date: any[] = [];
    const lastDate = new Date(endDate);
    const theDate = new Date(startDate);
    while (theDate < lastDate) {
      date = [...date, new Date(theDate).toLocaleDateString("en-US")];
      theDate.setDate(theDate.getDate() + 1);
    }
    const datesArray: Day[] = date.map((element: string) => {
      const tempArray = element.split("/");
      return {
        day: parseInt(tempArray[1]),
        month: parseInt(tempArray[0]),
        year: parseInt(tempArray[2])
      };
    });
    checkInDays.map((element) => {
      datesArray.map((item) => {
        if (
          element.day === item.day &&
          element.month === item.month &&
          element.year === item.year
        ) {
          setDates({ from: null, to: null });
        }
      });
    });
  }, [dates]);

  const checkBookingDays = useCallback(() => {
    if (dates.from && dates.to) {
      const endDate = dates.to?.year + "-" + dates.to?.month + "-" + dates.to?.day;
      if (
        dates.from.day === dates.to.day &&
        dates.from.month === dates.to.month &&
        dates.from.year === dates.to.year
      ) {
        const endDateInMs = new Date(endDate);
        const newEndDate = endDateInMs.setDate(endDateInMs.getDate() + 1);
        const newEndDateFormatted = new Date(newEndDate);
        const newEndDateString = newEndDateFormatted.toLocaleDateString("en-US");
        const newEndDateArray = newEndDateString.split("/");
        const nextDayDisabled = disabledDays.some(
          (element) =>
            element.day === parseInt(newEndDateArray[1]) &&
            element.month === parseInt(newEndDateArray[0]) &&
            element.year === parseInt(newEndDateArray[2])
        );
        if (!nextDayDisabled)
          setDates({
            ...dates,
            to: {
              day: parseInt(newEndDateArray[1]),
              month: parseInt(newEndDateArray[0]),
              year: parseInt(newEndDateArray[2])
            }
          });
        else setDates({ from: null, to: null });
      }
    }
  }, [dates]);

  const customizeCheckInDays = async () => {
    await getCheckInDays(setCheckInDays);
    const customizedDays = checkInDays.map((element) => {
      const item = { ...element, className: styles.checkInOnly };
      return item;
    });
    const customisedDisabled = disabledDays.map((element) => {
      const item = { ...element, className: styles.disabledDays };
      return item;
    });
    const customisedArray = customizedDays.concat(customisedDisabled);
    setCheckInDaysCustomised(customisedArray);
  };

  useEffect(() => {
    if (checkInDaysCustomised.length < 1) customizeCheckInDays();
  }, [disabledDays]);

  useEffect(() => {
    getDatesBetweenDates();
    getDisabledDays(setDisabledDays);
    checkBookingDays();
  }, [dates]);

  window.addEventListener("resize", handleResize);

  return (
    <div className={styles.container}>
      {showAddBox ? (
        <div className={styles.askReserveModal}>
          <div className={styles.reserveMainText}>
            Apasati Reserve pentru a rezerva datele alese. Altfel, apasati Cancel si reselectati!
          </div>
          <div className={styles.flexRow}>
            <button className={styles.reserveButton} onClick={reserveDates}>
              <span className={styles.reserveText}>Reserve</span>
            </button>
            <button className={styles.reserveButton} onClick={() => setShowAddBox(false)}>
              <span className={styles.reserveText}>Cancel</span>
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.calendarButtonContainer}>
        <Calendar
          value={dates}
          onChange={setDates}
          colorPrimary={"#FD8298"}
          colorPrimaryLight={"#FFC4CF"}
          minimumDate={currentDate}
          disabledDays={disabledDays}
          customDaysClassName={checkInDaysCustomised}
          calendarClassName={styles.addCalendar}
          renderFooter={() => (
            <div className={styles.calendarFooter}>
              <button className={styles.buttonStyle} onClick={() => setShowAddBox(true)}>
                <span className={styles.buttonText}>Adauga Rezervare</span>
              </button>
              <p className={styles.textCalendar}> CALENDAR ADAUGARE REZERVARE </p>
            </div>
          )}
        />
      </div>
      {showDeleteBox ? (
        <div className={styles.askReserveModal}>
          <div className={styles.reserveMainText}>
            Apasati Delete pentru a sterge datele alese. Altfel, apasati Cancel si reselectati!
          </div>
          <div className={styles.flexRow}>
            <button className={styles.reserveButton} onClick={deleteDates}>
              <span className={styles.reserveText}>Delete</span>
            </button>
            <button className={styles.reserveButton} onClick={() => setShowDeleteBox(false)}>
              <span className={styles.reserveText}>Cancel</span>
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.calendarButtonContainer}>
        <Calendar
          value={deletedDates}
          onChange={setDeletedDates}
          colorPrimary={"#FD8298"}
          colorPrimaryLight={"#FFC4CF"}
          minimumDate={currentDate}
          calendarClassName={styles.addCalendar}
          renderFooter={() => (
            <div className={styles.calendarFooter}>
              <button className={styles.buttonStyle} onClick={() => setShowDeleteBox(true)}>
                <span className={styles.buttonText}>Sterge Rezervare</span>
              </button>
              <p className={styles.textCalendar}>CALENDAR STERGERE REZERVARE</p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ManualReservation;
