const images = {
  calendarNext: require("../media/calendar/Next.svg").default,
  arrowDown: require("../media/icons/arrowDown.svg").default,
  arrowUp: require("../media/icons/arrowUp.svg").default,
  pinkArrowDown: require("../media/icons/pinkArrowDown.svg").default,
  logoNavbar: require("../media/icons/Logo.png"),
  fadeBackdrop: require("./../media/icons/fadeHeart.png"),
  cookie: require("./../media/icons/cookie.svg").default,
  infoIcon: require("./../media/icons/informationIcon.svg").default,
  infoIconPink: require("./../media/icons/infoIconPink.svg").default,
  closeButton: require("./../media/icons/closeButton.svg").default,
  bookingActions: {
    airBnb: require("../media/icons/airbnb.svg").default,
    bookingCom: require("../media/icons/bookingcom.svg").default
  },
  socialIcons: {
    facebookIcon: require("../media/icons/facebookSocial.svg").default,
    instagramIcon: require("../media/icons/instagramSocial.svg").default
  },
  reviewsIcons: {
    fullstar: require("../media/icons/fullStar.svg").default,
    halfStar: require("../media/icons/halfStar.svg").default
  },
  paymentStatus: {
    denied: require("../media/icons/denied.png"),
    success: require("../media/icons/success.png")
  },
  reviewsRatingIcons: {
    star: require("../media/icons/star.svg").default,
    review: require("../media/icons/review.svg").default
  },

  firstPage: {
    relax: require("../media/firstPageCarousel/relax.png"),
    relaxMobile: require("../media/firstPageCarousel/relaxMobile.png"),
    relaxTablet: require("../media/firstPageCarousel/relaxTablet.png"),
    studio: require("../media/firstPageCarousel/studio.png"),
    studioMobile: require("../media/firstPageCarousel/studioMobile.png"),
    studioTablet: require("../media/firstPageCarousel/studioTablet.png"),
    view: require("../media/firstPageCarousel/view.png"),
    leftArrow: require("../media/firstPageCarousel/leftArrow.svg").default,
    rightArrow: require("../media/firstPageCarousel/rightArrow.svg").default,
    leftArrowHover: require("../media/firstPageCarousel/leftArrowHover.svg").default,
    rightArrowHover: require("../media/firstPageCarousel/rightArrowHover.svg").default,
    phone: require("../media/firstPage/phone.svg").default,
    phoneHover: require("../media/firstPage/phoneHover.svg").default,
    mail: require("../media/firstPage/mail.svg").default,
    mailHover: require("../media/firstPage/mailHover.svg").default,
    chat: require("../media/firstPage/chat.svg").default,
    chatHover: require("../media/firstPage/chatHover.svg").default
  },

  photoGalery: {
    photo1: require("../media/photoGalery/photo1.jpg"),
    photo2: require("../media/photoGalery/photo2.jpg"),
    photo3: require("../media/photoGalery/photo3.jpg"),
    photo4: require("../media/photoGalery/photo4.jpg"),
    photo5: require("../media/photoGalery/photo5.jpg"),
    photo6: require("../media/photoGalery/photo6.jpg"),
    photo7: require("../media/photoGalery/photo7.jpg"),
    photo8: require("../media/photoGalery/photo8.jpg"),
    photo9: require("../media/photoGalery/photo9.jpg"),
    photo10: require("../media/photoGalery/photo10.jpg"),
    leftArrow: require("../media/photoGalery/leftArrow.svg").default,
    rightArrow: require("../media/photoGalery/rightArrow.svg").default,
    leftArrowHover: require("../media/photoGalery/leftArrowHover.svg").default,
    rightArrowHover: require("../media/photoGalery/rightArrowHover.svg").default
  },

  logoFooter: require("../media/icons/Logo.svg").default,
  nmcpLogo: require("../media/icons/nmcpLogo.svg").default
};
export const gallery = {
  bathChamp: require("./../media/pictures/bathChamp.png"),

  facilitiesPack: {
    netflix: require("./../media/icons/whyUs/netflix.svg").default,
    panorama: require("./../media/icons/whyUs/panorama.svg").default,
    chill: require("./../media/icons/whyUs/chill.svg").default,
    servingWine: require("./../media/icons/whyUs/drinks.svg").default,
    aircondition: require("./../media/icons/whyUs/aircondition.svg").default,
    bathJacuzzi: require("./../media/icons/whyUs/bathJacuzzi.svg").default,
    soap: require("./../media/icons/whyUs/soap.svg").default,
    servingFood: require("./../media/icons/whyUs/servingFood.svg").default
  }
};
export default images;
