import React from "react";
import styled from "styled-components";
import "./styles.css";
interface IProps {
  classStyle?: string;
  style: {
    textDecoration: string;
    background: string;
    color: string;
  };
  onClick: () => void;
  children: JSX.Element;
  title: string;
}
export const LeftButton = styled.button`
  align-self: flex-start;
  background: none;
  border: 0;
  left: 40px;
  top: calc(1.5em + 15px);
  cursor: pointer;
`;

export const RightButton = styled.button`
  align-self: flex-start;
  background: none;
  border: 0;
  right: 40px;
  top: calc(1.5em + 15px);
  cursor: pointer;
`;
export const MonthWrapper = styled.div`
  width: fit-content;
  h3 {
    text-align: center;
  }
`;

export const MonthTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-family: Inter;
  justify-content: center;
  color: #fd8298;
`;

export const H3 = styled.div`
  display: block;
  text-align: center;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #fd8298;
`;
export const DayContainer = ({ classStyle, onClick, style, children, title }: IProps) => {
  return (
    <div onClick={onClick} style={style} className={"dayContainerS "}>
      {classStyle != "" ? <div className={classStyle}>{title}</div> : ""}
      {children}
    </div>
  );
};

export const MonthContainer = styled.div`
  grid-template-columns: repeat(7, 48px);

  @media (max-width: 768px) {
    grid-template-columns: repeat(7, 40px);
  }
  display: grid;
`;

export const WeekDaysWrapper = styled.div`
  grid-template-columns: repeat(7, 48px);
  display: grid;
  @media (max-width: 768px) {
    grid-template-columns: repeat(7, 40px);
  }
  div {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #666666;
  }
`;
