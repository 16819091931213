import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "./../../functions/functions";

import styles from "./CookieConsent.module.scss";
import { cookies } from "../../constants/stringConst";
import images from "../../data/images";

const CookieConsent = () => {
  const { t } = useTranslation();
  const [renderConsent, setConsent] = useState<boolean>(true);

  const getCookieConsent = () => {
    if (getCookie("cookieConsentBrasov") === "userAccepted") return true;
    else return false;
  };

  const acceptCookie = () => {
    setCookie("cookieConsentBrasov", "userAccepted");
    setConsent(false);
  };
  useEffect(() => {
    setConsent(!getCookieConsent());
  }, [renderConsent]);

  return (
    renderConsent && (
      <div className={styles.cookiePopper}>
        <div className={styles.textArea}>
          <h4>
            <img src={images.cookie} />
            {t(cookies.headTitle)}
          </h4>
          <p>
            {t(cookies.mainText)}
            <a href="/termeni-si-conditii">{t(cookies.secondText)}</a>
          </p>
        </div>
        <div className={styles.acceptWrap}>
          <button onClick={acceptCookie}>{t(cookies.acceptButton)}</button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
