import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useOnScreen } from "../hooks/onScreen";
import ReactGA from "react-ga4";
import { uniqueId } from "lodash";

import { footerConstants } from "../constants/stringConst";
import images from "./../data/images";

import styles from "./Footer.module.scss";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_Footer");
    }
  }, [isVisible]);
  return (
    <div ref={ref} id="contactus" className={styles.footerContainer}>
      <div className={styles.footerItems}>
        <img className={styles.logoStyle} src={images.logoFooter} />
        <div className={styles.socialGroup}>
          {Object.values(footerConstants.socialData).map((item) => (
            <a key={uniqueId()} href={item.link}>
              <img
                className={styles.iconFooter}
                src={item.icon}
                alt="Romantic Studio Apartament logo"
              />
            </a>
          ))}
        </div>
        <div>
          <ul className={styles.ulItem}>
            {Object.values(footerConstants.footerItemList).map((item) => (
              <NavHashLink
                className={styles.HashLinkStyle}
                to={`/#${t(item.link)}`}
                key={uniqueId()}>
                {t(item.text)}
              </NavHashLink>
            ))}
          </ul>
        </div>
        <div className={styles.longLine}>
          {Object.values(footerConstants.address).map((item, index) => (
            <p key={index} className={styles.pFooter}>
              {t(item)}
            </p>
          ))}
        </div>
        <div className={styles.copyrightItem}>
          <p>{t(footerConstants.copyrightStuff.copyText)}</p>
          <img className={styles.bottomLogo} src={images.nmcpLogo} alt="Nomorecoffeeplease logo" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
