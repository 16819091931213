import React from "react";
import styles from "./HeadText.module.scss";

interface HeadProps {
  topText: string;
  bottomText: string;
}
const HeadText = ({ topText, bottomText }: HeadProps) => {
  return (
    <div className={styles.cardboardWhy}>
      <h3 className={styles.bookTextTop}>{topText}</h3>
      <h3 className={styles.bookTextDown}>{bottomText}</h3>
    </div>
  );
};

export default HeadText;
