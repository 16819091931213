import images from "./images";
import { componentStrings } from "./strings";
import { firstPage } from "./../constants/stringConst";
export const firstPageArray = [
  {
    image: images.firstPage.studio,
    imageMobile: images.firstPage.studioMobile,
    imageTablet: images.firstPage.studioTablet,
    title: firstPage.arraySlide.studio.title,
    description: firstPage.arraySlide.studio.description
  },
  {
    image: images.firstPage.view,
    imageMobile: images.firstPage.view,
    imageTablet: images.firstPage.view,
    title: firstPage.arraySlide.view.title,
    description: firstPage.arraySlide.view.description
  },
  {
    image: images.firstPage.relax,
    imageMobile: images.firstPage.relaxMobile,
    imageTablet: images.firstPage.relaxTablet,
    title: firstPage.arraySlide.relax.title,
    description: firstPage.arraySlide.relax.description
  },
  {
    image: images.firstPage.studio,
    imageMobile: images.firstPage.studioMobile,
    imageTablet: images.firstPage.studioTablet,
    title: firstPage.arraySlide.studio.title,
    description: firstPage.arraySlide.studio.description
  }
];

export const reviewArrayDesktop = {
  reviewsArray: [
    [componentStrings.Reviews.clientsList[1], componentStrings.Reviews.clientsList[2]],
    [componentStrings.Reviews.clientsList[3], componentStrings.Reviews.clientsList[4]]
  ],
  reviewsArray1: [
    [componentStrings.Reviews.clientsList[5], componentStrings.Reviews.clientsList[6]],
    [componentStrings.Reviews.clientsList[7], componentStrings.Reviews.clientsList[8]]
  ],
  reviewsArray2: [
    [componentStrings.Reviews.clientsList[9], componentStrings.Reviews.clientsList[10]],
    [componentStrings.Reviews.clientsList[11], componentStrings.Reviews.clientsList[12]]
  ]
};

export const reviewArrayMobile = {
  reviewsArray: [
    [
      componentStrings.Reviews.clientsList[1],
      componentStrings.Reviews.clientsList[2],
      componentStrings.Reviews.clientsList[3]
    ]
  ],
  reviewsArray1: [
    [
      componentStrings.Reviews.clientsList[4],
      componentStrings.Reviews.clientsList[5],
      componentStrings.Reviews.clientsList[6]
    ]
  ],
  reviewsArray2: [
    [
      componentStrings.Reviews.clientsList[7],
      componentStrings.Reviews.clientsList[8],
      componentStrings.Reviews.clientsList[9]
    ]
  ],
  reviewsArray3: [
    [
      componentStrings.Reviews.clientsList[10],
      componentStrings.Reviews.clientsList[11],
      componentStrings.Reviews.clientsList[12]
    ]
  ]
};

export const photoGaleryArray = [
  images.photoGalery.photo1,
  images.photoGalery.photo2,
  images.photoGalery.photo3,
  images.photoGalery.photo4,
  images.photoGalery.photo5,
  images.photoGalery.photo6,
  images.photoGalery.photo7,
  images.photoGalery.photo8,
  images.photoGalery.photo9,
  images.photoGalery.photo10
];
