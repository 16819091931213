import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HeadText from "./../components/HeadText";
import OffersCarousel, { CarouselItem } from "../components/OffersCarousel";
import OfferCard from "../components/OfferCard";
import BookingComponent from "./BookingComponent";
import { useOutsideClicker } from "../hooks/onScreen";
import { componentStrings, OffersDetailsText } from "../data/strings";
import { OffersConst } from "../constants/stringConst";
import styles from "./OffersSection.module.scss";
import images from "../data/images";

interface chooseProps {
  [key: string]: {
    name: string;
    price: number;
  };
}
const OffersSection = () => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [bookingPop, setBookingPop] = useState<boolean>(false);
  const [offerSelected, setOfferSelect] = useState<string>("");

  useEffect(() => {
    if(screenWidth > 576) {
      setActiveIndex(1);
    }
  }, [screenWidth])

  const offersPredefault: chooseProps = {
    relaxPack: componentStrings.bookingComponent.sericePack.relaxPack,
    romanticPack: componentStrings.bookingComponent.sericePack.romanticPack,
    lovelyPack: componentStrings.bookingComponent.sericePack.lovelyPack
  };

  const backdropRef = useRef(null);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  const backdropClose = () => {
    setBookingPop(false);
  };
  useOutsideClicker(backdropRef, () => {
    backdropClose();
  });

  const offerHandler = (offerIndex: string) => {
    setOfferSelect(offerIndex);
  };

  window.addEventListener("resize", handleResize);
  return (
    <>
      <div className={styles.offersBlock} id="offers">
        <HeadText topText={t(OffersConst.topText)} bottomText={t(OffersConst.bottomText)} />
        {bookingPop && (
          <div ref={backdropRef} className={styles.backDrops}>
            <div className={styles.closePop} onClick={backdropClose}>
              X
            </div>
            <BookingComponent
              checkedOptions={{
                [offerSelected]: offersPredefault[offerSelected]
              }}
              floating={true}
            />
          </div>
        )}
        {screenWidth < 576 ? (
          <div className={styles.offerList}>
            <OffersCarousel
              leftArrow={images.photoGalery.leftArrow}
              rightArrow={images.photoGalery.rightArrow}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
            >
              {Object.values(componentStrings.OffersCards.offers).map((item, index) => (
                <CarouselItem key={index} width={"100%"}>
                  <OfferCard
                    cardName={item.cardName}
                    title={item.title}
                    price={item.price}
                    packIncludes={item.featuresList}
                    currency={item.currency}
                    activeIndex={activeIndex}
                    index={index}
                    setBookingPop={setBookingPop}
                    offerChoosed={offerHandler}
                  />
                </CarouselItem>
              ))}
            </OffersCarousel>
          </div>
        ) : (
          <div className={styles.offerList}>
            {Object.values(componentStrings.OffersCards.offers).map((item, index) => (
              <OfferCard
                key={index}
                cardName={item.cardName}
                title={item.title}
                price={item.price}
                packIncludes={item.featuresList}
                currency={item.currency}
                activeIndex={activeIndex}
                index={index}
                setBookingPop={setBookingPop}
                offerChoosed={offerHandler}
              />
            ))}
          </div>
        )}
        {screenWidth > 576 ? (
          <div className={styles.underText}>
            {t(componentStrings.OffersCards.pricesInfo, {
              monday: t(OffersDetailsText.monday),
              thursday: t(OffersDetailsText.thursday),
              friday: t(OffersDetailsText.friday),
              sunday: t(OffersDetailsText.sunday),
              relaxPackPrice: OffersDetailsText.relaxPackPrice,
              relaxPackPriceWeekend: OffersDetailsText.relaxPackPriceWeekend,
              lovelyPackPrice: OffersDetailsText.lovelyPackPrice,
              lovelyPackPriceWeekend: OffersDetailsText.lovelyPackPriceWeekend,
              romanticPackPrice: OffersDetailsText.romanticPackPrice,
              romanticPackPriceWeekend: OffersDetailsText.romanticPackPriceWeekend
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OffersSection;
