import React, { useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { reviewsRating } from "./../constants/stringConst";
import { useTranslation } from "react-i18next";

import { useOnScreen } from "../hooks/onScreen";
import images from "../data/images";
import styles from "./ReviewsRating.module.scss";

const ReviewsRating = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_ReviewsRating");
    }
  }, [isVisible]);

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.reviewContainer}>
        <div className={styles.bookingLine}></div>
        <span className={styles.bookingText}>{t(reviewsRating.booking)}</span>
        <div
          style={{
            backgroundImage: `url(${images.reviewsRatingIcons.review})`
          }}
          className={styles.review}>
          <span className={styles.scoreText}>{t(reviewsRating.bookingScore)}</span>
          <span className={styles.outOffText}>{t(reviewsRating.bookingOutOff)}</span>
        </div>
      </div>
      <div className={styles.reviewContainer}>
        <div className={styles.reviewLine}></div>
        <span className={styles.reviewTextMobile}>{t(reviewsRating.airbnb)}</span>
        <div
          style={{
            backgroundImage: `url(${images.reviewsRatingIcons.star})`
          }}
          className={styles.star}>
          <span className={styles.scoreText}>{t(reviewsRating.airbnbScore)}</span>
          <span className={styles.outOffText}>{t(reviewsRating.airbnbOutOff)}</span>
        </div>
        <span className={styles.reviewText}>{t(reviewsRating.airbnb)}</span>
      </div>
    </div>
  );
};

export default ReviewsRating;
