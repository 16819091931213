import React, { useEffect, useState } from "react";
import PhotoGaleryCarousel, { CarouselItem } from "../components/PhotoGaleryCarousel";
import { photoGaleryArray } from "../data/arrays";
import images from "../data/images";
import { componentStrings } from "../data/strings";
import styles from "./PhotoGalery.module.scss";

const PhotoGalery = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [leftHover, setLeftHover] = useState<boolean>(false);
  const [rightHover, setRightHover] = useState<boolean>(false);
  const [photoArray, setPhotoArray] = useState<Array<string>>([]);

  const handleLeftArrowHover = () => {
    setLeftHover(leftHover ? false : true);
  };

  const handleRightArrowHover = () => {
    setRightHover(rightHover ? false : true);
  };

  useEffect(() => {
    setPhotoArray(photoGaleryArray);
  });

  useEffect(() => {
    if (activeIndex > photoArray.length - 4) {
      photoArray.push(photoGaleryArray[activeIndex - 7]);
    }
  }, [activeIndex]);

  const nextImage = () => {
    setActiveIndex(activeIndex + 1);
  };

  const previousImage = () => {
    if (activeIndex > -1) {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <div className={styles.container} id="gallery">
      <div className={styles.titleContainer}>
        <img
          onMouseEnter={handleLeftArrowHover}
          onMouseLeave={handleLeftArrowHover}
          onClick={previousImage}
          src={leftHover ? images.photoGalery.leftArrowHover : images.photoGalery.leftArrow}
          className={styles.leftArrow}
        />
        <div className={styles.textContainer}>
          <span className={styles.title}>{componentStrings.PhotoGallery.title}</span>
          <span className={styles.subTitle}>{componentStrings.PhotoGallery.subTitle}</span>
        </div>
        <img
          onMouseEnter={handleRightArrowHover}
          onMouseLeave={handleRightArrowHover}
          onClick={nextImage}
          src={rightHover ? images.photoGalery.rightArrowHover : images.photoGalery.rightArrow}
          className={styles.rightArrow}
        />
      </div>
      <div className={styles.carouselContainer}>
        <PhotoGaleryCarousel activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
          {photoArray.map((item, index) => {
            return (
              <CarouselItem key={index} width={"100%"}>
                <img
                  src={item}
                  className={styles.photo}
                  alt="Apartamentul văzut înăuntru cu șampanie flori jacuzzi trandafiri "
                />
              </CarouselItem>
            );
          })}
        </PhotoGaleryCarousel>
      </div>
    </div>
  );
};

export default PhotoGalery;
