import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["RO", "EN"];
var identifiedLanguage = "RO";
if (navigator.language.includes("en")) identifiedLanguage = "EN";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "RO",
    lng: identifiedLanguage,
    debug: true,
    whitelist: languages,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
