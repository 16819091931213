import React from "react";
import FAQBlock from "../pageblocks/FAQBlock";
import Reviews from "../pageblocks/Reviews";
import Footer from "../pageblocks/Footer";
import FirstPage from "../pageblocks/FirstPage";
import WhyUs from "../pageblocks/WhyUs";
import ReviewsRating from "../pageblocks/ReviewsRating";
import OffersSection from "../pageblocks/OffersSection";
import PhotoGalery from "../pageblocks/PhotoGalery";
import BookingArea from "../pageblocks/BookingArea";

const MainNavigation = () => {
  return (
    <>
      <FirstPage />
      <WhyUs />
      <ReviewsRating />
      <Reviews />
      <PhotoGalery />
      <OffersSection />
      <BookingArea />
      <FAQBlock />
      <Footer />
    </>
  );
};
export default MainNavigation;
