import React, { Children, useCallback, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

import styles from "./Carousel.module.scss";

interface IProps {
  children: JSX.Element[];
  activeStyle: string;
  inactiveStyle: string;
}

interface CarouselItemType {
  children: object;
  width: string;
}

export const CarouselItem: React.FC<CarouselItemType> = ({ children, width }) => {
  return (
    <div className={styles.carouselItem} style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel: React.FC<IProps> = ({ children, activeStyle, inactiveStyle }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [paused, setPaused] = useState<boolean>(false);
  const [mouseStart, setMouseStart] = useState<number>();
  const [mouseEnd, setMouseEnd] = useState<number>();
  const [mouseHold, setMouseHold] = useState<boolean>(false);
  const [direction, setDirection] = useState<boolean>(false);

  const onMouseDown = (e: { clientX: React.SetStateAction<number | undefined> }) => {
    setMouseEnd(0);
    setMouseStart(e.clientX);
    setMouseHold(true);
  };

  const onMouseMove = (e: { clientX: React.SetStateAction<number | undefined> }) =>
    setMouseEnd(e.clientX);

  const onMouseUp = useCallback(() => {
    setMouseHold(false);
    if (!mouseStart || !mouseEnd) return;
    const distance = mouseStart - mouseEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe && activeIndex < Children.count(children) - 1) {
      setActiveIndex(activeIndex + 1);
    } else if (isRightSwipe && activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  }, [mouseStart, mouseEnd, activeIndex, children]);

  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = React.Children.count(children) - 1;
      } else if (newIndex >= React.Children.count(children)) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [children]
  );

  useEffect(() => {
    if (activeIndex === 0) {
      setDirection(false);
    } else if (activeIndex === Children.count(children) - 1) {
      setDirection(true);
    }
  }, [activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused && !direction) {
        updateIndex(activeIndex + 1);
      } else if (!paused && direction) {
        updateIndex(activeIndex - 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <div
      {...handlers}
      className={styles.carousel}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        className={mouseHold ? styles.innerActive : styles.inner}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child: JSX.Element) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className={styles.indicators}>
        {React.Children.map(children, (child, index: number) => {
          return (
            <div
              className={index === activeIndex ? activeStyle : inactiveStyle}
              onClick={() => {
                updateIndex(index);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
