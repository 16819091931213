import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import validator from "validator";
import styles from "./NewsletterPop.module.scss";
import images from "./../data/images";
import InputComponent from "./InputComponent";
import { NewsProps, responseProps } from "./../utils/interfaceExport/NewsletterInterface";
import { addToNewsletter } from "./../functions/newsletter";
import { verifyIfSubscribed } from "../functions/firebase";
import { DocumentData } from "firebase/firestore";

interface EventInsert {
  [key: string]: string;
}

interface Props {
  close: () => void;
}

const NewsletterPop: React.FC<Props> = ({ close }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<"valid" | "notvalid" | "init">("init");
  const [newsletterData, setNewsletterData] = useState<NewsProps>({
    firstName: "",
    lastName: "",
    email: ""
  });
  const [userSubscribed, setSubscribed] = useState<boolean>(false);
  const [eventInsert, setEventInsert] = useState<EventInsert>(null);
  const [subscribedMails, setSubscribedMails] = useState<DocumentData>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const emailValidate = (email: string) => {
    if (validator.isEmail(email)) return true;
    else return false;
  };
  const sendEvent = (eventLabel: string) => {
    if (eventInsert === null || Object.prototype.hasOwnProperty.call(!eventInsert, eventLabel)) {
      setEventInsert((eventInsert) => ({ ...eventInsert, [eventLabel]: "Triggered" }));
      ReactGA.event(`user_Newsletterinput_${eventLabel}`);
    }
  };

  const inputHandler = (data: React.ChangeEvent<HTMLInputElement>) => {
    sendEvent(data.target.name);
    const { name, value } = data.target;
    setNewsletterData((newsletterData) => ({
      ...newsletterData,
      [name]: value
    }));
  };
  const subscribeToNewsletter = () => {
    if (isChecked && emailValid === "valid") {
      addToNewsletter(newsletterData).then((result) => {
        result.json().then((response: responseProps) => {
          if (response.subscribeToNewsletter) {
            setSubscribed(true);
          }
        });
      });
      setLoading(true);
    }
  };

  const getSubscribedEmails = async () => {
    const data = await verifyIfSubscribed();
    setSubscribedMails(data);
  };

  useEffect(() => {
    getSubscribedEmails();
  }, [newsletterData]);

  useEffect(() => {
    if (emailValidate(newsletterData.email) && !subscribedMails.includes(newsletterData.email)) {
      setEmailValid("valid");
    } else if (newsletterData.email.length < 1) {
      setEmailValid("init");
    } else {
      setEmailValid("notvalid");
    }
  }, [newsletterData.email]);

  const layoutTransition = () => {
    if (userSubscribed) {
      return `${styles.rightPanel} ${styles.imageSubscribed}`;
    } else {
      return `${styles.rightPanel}`;
    }
  };

  const Spinner = () => <div className={styles.loader}></div>;

  return (
    <div className={styles.newsletterContainer}>
      <div className={!userSubscribed ? styles.imageFeatured : styles.imageFeaturedTransition}>
        <div className={styles.textArea}>
          <h3 className={!userSubscribed ? styles.title : styles.titleSubscribed}>
            {!userSubscribed ? "Abonează-te" : "Felicitari!"}
          </h3>
          <h4 className={!userSubscribed ? styles.description : styles.descriptionSubscription}>
            {!userSubscribed ? "la newsletter" : "Te-ai abonat cu succes!"}
          </h4>
        </div>
      </div>
      <div className={layoutTransition()}>
        <a onClick={() => close()}>
          <img className={styles.closeButton} src={images.closeButton} />
        </a>
        <div className={styles.containerWrap}>
          <img className={styles.iconInfo} src={images.infoIcon} />
          <div className={styles.textContain}>
            <p className={styles.newsletterText}>
              {
                "Cu ajutorul newsletter-ului ai acces la ofertele actuale,reducerile noastre și multe alte surprize.Pentru a te abona completează câmpurile de mai jos  "
              }
            </p>
          </div>
          <div className={styles.inputArea}>
            <input
              type={"inputNewsletter"}
              autoComplete="off"
              id="lastName"
              name="lastName"
              placeholder={"Nume"}
              value={newsletterData.lastName}
              onChange={inputHandler}
            />
            <input
              type={"inputNewsletter"}
              autoComplete="off"
              id="firstName"
              name="firstName"
              placeholder={"Prenume"}
              value={newsletterData.firstName}
              onChange={inputHandler}
            />
            <input
              className={emailValid === "valid" || emailValid === "init" ? "" : styles.invalidEmail}
              type={"inputNewsletter"}
              autoComplete="off"
              id="email"
              name="email"
              placeholder={"E-mail"}
              value={newsletterData.email}
              onChange={inputHandler}
            />
            {subscribedMails.includes(newsletterData.email) ? (
              <div className={styles.alreadyErrorText}>Already subscribed</div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.termArea}>
            <InputComponent
              theme={"blue"}
              typeOfInput="checkbox"
              onSwitchEnabled={null}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
            <p className={styles.termsText}>
              {
                "Sunt de acord cu preluarea datelor cu caracter personal pentru a primi oferte personalizate"
              }
            </p>
          </div>
          <button onClick={subscribeToNewsletter} className={styles.newsSubscribeButton}>
            {loading ? <Spinner /> : "Aboneaza-te"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterPop;
