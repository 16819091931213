import { NewsProps } from "../utils/interfaceExport/NewsletterInterface";

const destination =
  "https://us-central1-brasovromanticapartment.cloudfunctions.net/subscribeToNewsletter";
// const destination = 'http://localhost:5000/brasovromanticapartment/us-central1';

export const addToNewsletter = async (subscriberData: NewsProps) => {
  return await fetch(`${destination}/subscribeToNewsletter`, {
    credentials: "include",
    method: "POST",
    mode: "cors",
    body: JSON.stringify(subscriberData)
  })
    .then((res) => res)
    .catch((error) => error);
};
