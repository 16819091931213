import React, { Suspense } from "react";
import "i18next";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { MessengerChat } from "react-messenger-chat-plugin";
import MainNavigation from "./components/MainNavigation";
import { useAttachUserID } from "./hooks/onScreen";
import "./App.scss";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import { getCookie } from "./functions/functions";
import { messengerChat } from "./constants/stringConst";
import ManualReservation from "./pageblocks/ManualReservation";

ReactGA.pageview("/");

function App() {
  ReactGA.set({ userID: useAttachUserID() });
  const { t } = useTranslation();
  const getCookieConsent = () => {
    if (getCookie("cookieConsentBrasov") === "userAccepted") return false;
    else return true;
  };
  window.addEventListener("unload", () => {
    ReactGA.event({
      category: "Button",
      action: "user_exit"
    });
  });

  return (
    <div className="App">
      <MessengerChat
        language="RO"
        pageId="145535741007540"
        themeColor="#FD8298"
        loggedInGreeting={t(messengerChat.loggedInMessage)}
        loggedOutGreeting={t(messengerChat.loggedOutMessage)}
      />
      {getCookieConsent() && <CookieConsent />}

      <Router>
        <Routes>
          <Route
            path="/manual"
            element={
              <Suspense fallback="-">
                <ManualReservation />{" "}
              </Suspense>
            }></Route>
          <Route path="/" element={<MainNavigation />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
