import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./BookingArea.module.scss";
import BookingComponent from "./BookingComponent";
import ReactGA from "react-ga4";
import images from "../data/images";
import { bookingComponent } from "../constants/stringConst";

const BookingArea = () => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const airBnbClick = useCallback(() => {
    ReactGA.event("user_click_airbnbButton");
  }, []);
  const bookingComClick = useCallback(() => {
    ReactGA.event("user_click_BookingComButton");
  }, []);

  return (
    <div className={styles.bookingAreaContainer}>
      <div ref={ref} className={styles.bookingBlock} id="booking">
        <h2 className={styles.bookTextTop}>{t(bookingComponent.topMessage)}</h2>
        <h2 className={styles.bookTextDown}>{t(bookingComponent.bottomMessage)}</h2>
        <BookingComponent closeButton={false} />
        <div className={styles.bookAction}>
          <a
            onClick={airBnbClick}
            className={styles.airbnbButton}
            href={"https://www.airbnb.com/rooms/51572270"}
            target="_blank"
            rel="noreferrer">
            <img className={styles.bookingStyle} src={images.bookingActions.airBnb} alt="airbnb" />
          </a>
          <a
            onClick={bookingComClick}
            className={styles.bookingButton}
            href={"https://www.booking.com/hotel/ro/romantic-studio.en-gb.html"}
            target="_blank"
            rel="noreferrer">
            <img
              className={styles.bookingStyle}
              src={images.bookingActions.bookingCom}
              alt="booking.com"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookingArea;
