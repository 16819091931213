import { useTranslation } from "react-i18next";
import QuestionCardboard from "../components/QuestionCardboard";
import { FAQBlockConst } from "../constants/stringConst";
import React, { useEffect, useRef } from "react";
import { uniqueId } from "lodash";
import ReactGA from "react-ga4";
import styles from "./../pageblocks/FAQBlock.module.scss";

import { useOnScreen } from "../hooks/onScreen";

const FAQBlock = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_FAQ");
    }
  }, [isVisible]);
  return (
    <div ref={ref} className={styles.questionsBlock}>
      <h3 className={styles.bookTextTop}>{t(FAQBlockConst.topMessage)}</h3>
      <h3 className={styles.bookTextDown}>{t(FAQBlockConst.bottomMessage)}</h3>

      <div className={styles.cardsWrapper}>
        {Object.values(FAQBlockConst.questionList).map((item) => (
          <QuestionCardboard
            key={uniqueId()}
            theQuestion={{ question: t(item.question), answer: t(item.answer) }}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQBlock;
