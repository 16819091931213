import React, { Children, cloneElement, useCallback, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import ReactGA from "react-ga4";

import styles from "./FirstPageCarousel.module.scss";

interface IProps {
  children: JSX.Element[];
  leftArrow: string;
  rightArrow: string;
  leftArrowHover: string;
  rightArrowHover: string;
  setActiveIndex: (activeIndex: number) => void;
  activeIndex: number;
  nextPhoto: () => void;
}

interface CarouselItemType {
  children: object;
  width: string;
}

export const CarouselItem: React.FC<CarouselItemType> = ({ children, width }) => {
  return (
    <div
      className={styles.carouselItem}
      style={{ width: width }}
      role="img"
      aria-label="Minunatele priveliști din apartament dar și din afara apartamentului">
      {children}
    </div>
  );
};

const FirstPageCarousel: React.FC<IProps> = ({
  children,
  leftArrow,
  rightArrow,
  leftArrowHover,
  rightArrowHover,
  setActiveIndex,
  activeIndex,
  nextPhoto
}) => {
  const [paused, setPaused] = useState<boolean>(false);
  const [leftHover, setLeftHover] = useState<boolean>(false);
  const [rightHover, setRightHover] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  let interval: NodeJS.Timeout;

  window.addEventListener("blur", () => setPaused(true));
  window.addEventListener("focus", () => setPaused(false));

  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = Children.count(children) - 1;
      } else if (newIndex >= Children.count(children)) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [children]
  );

  useEffect(() => {
    if (activeIndex === 0) {
      setLeftHover(false);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!paused) {
      interval = setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    }
  });

  const resetTimer = () => {
    setCounter(0);
    clearTimeout(interval);
  };

  useEffect(() => {
    if (counter === 5) {
      nextIdleImage();
      resetTimer();
    }
  });

  const handleLeftArrowHover = () => {
    setLeftHover(leftHover ? false : true);
    setPaused(paused ? false : true);
    resetTimer();
  };

  const handleRightArrowHover = () => {
    setRightHover(rightHover ? false : true);
    setPaused(paused ? false : true);
    resetTimer();
  };

  const previousImage = () => {
    updateIndex(activeIndex - 1);
    resetTimer();
  };

  const nextImage = () => {
    ReactGA.event("user_MainCarousel_swipeButton");
    nextPhoto();
    updateIndex(activeIndex + 1);
    resetTimer();
  };

  const nextIdleImage = () => {
    ReactGA.event("user_MainCarousel_swipeButton");
    nextPhoto();
    updateIndex(activeIndex + 1);
    resetTimer();
  };

  const handlers = useSwipeable({
    onSwiping: () => {
      resetTimer();
    },
    onSwipedLeft: () => {
      nextImage();
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        previousImage();
      }
    }
  });

  return (
    <div {...handlers} className={styles.carousel} id="home">
      <div className={styles.inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {Children.map(children, (child: JSX.Element) => {
          return cloneElement(child, { width: "100%" });
        })}
      </div>
      {activeIndex > 0 ? (
        <img
          onMouseEnter={handleLeftArrowHover}
          onMouseLeave={handleLeftArrowHover}
          onClick={previousImage}
          src={leftHover ? leftArrowHover : leftArrow}
          className={styles.leftArrow}
        />
      ) : (
        <></>
      )}

      <img
        onMouseEnter={handleRightArrowHover}
        onMouseLeave={handleRightArrowHover}
        onClick={nextImage}
        src={rightHover ? rightArrowHover : rightArrow}
        className={styles.rightArrow}
      />
    </div>
  );
};

export default FirstPageCarousel;
