import React from "react";
import { navMenu } from "./../constants/stringConst";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import images from "./../data/images";
import styles from "./../components/Navbar.module.scss";

import { componentStrings } from "../data/strings";

interface IProps {
  burgerOpen: boolean;
  setBurgerOpen: (burgerOpen: boolean) => void;
}

const MenuMobile: React.FC<IProps> = ({ burgerOpen, setBurgerOpen }) => {
  const { t } = useTranslation();
  const burgerClickHandler = () => {
    setBurgerOpen(!burgerOpen);
  };
  const closeBackdrop = () => {
    setBurgerOpen(false);
  };

  return (
    <>
      <div className={styles.menuMobileContainer}>
        <div className={styles.burgerElement} onClick={burgerClickHandler}>
          <div className={burgerOpen ? styles.bar1End : styles.bar1}></div>
          <div className={burgerOpen ? styles.bar2End : styles.bar2}></div>
          <div className={burgerOpen ? styles.bar3End : styles.bar3}></div>
        </div>
      </div>
      <div
        onClick={burgerClickHandler}
        className={burgerOpen ? styles.active : styles.backDropBackground}></div>
      <div className={burgerOpen ? styles.activeBurger : styles.burgerMenu}>
        <div className={styles.headText}>
          <h1>{componentStrings.mobileTitle}</h1>
        </div>
        <ul className={styles.ulMobile}>
          {Object.values(navMenu.items).map((item, index) => (
            <li key={index} onClick={closeBackdrop} className={styles.liMobile}>
              <NavHashLink className={styles.HashLinkStyle} to={`/#${t(item.link)}`}>
                {t(item.text)}
              </NavHashLink>
            </li>
          ))}
        </ul>
        <div className={styles.fadeContainer}>
          <img className={styles.fadeBack} src={images.fadeBackdrop} />
        </div>
      </div>
    </>
  );
};

export default MenuMobile;
