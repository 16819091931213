import React, { useEffect, useMemo, useState, FC } from "react";
import {
  DisabledDays,
  dataPro,
  Props,
  MonthsProps,
  daysInMDateType
} from "./../../utils/interfaceExport/CalendarInterface";
import {
  LeftButton,
  RightButton,
  MonthWrapper,
  MonthTitle,
  H3,
  WeekDaysWrapper,
  MonthContainer,
  DayContainer
} from "./StyleComponents";
import { componentStrings } from "../../data/strings";
import { ReactComponent as LeftArrow } from "./assets/left.svg";
import { ReactComponent as RightArrow } from "./assets/right.svg";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { bookingComponent } from "../../constants/stringConst";

export const NewCalendar: FC<Props> = ({
  children,
  dates,
  setDates,
  disabledDays,
  noMonth,
  open,
  className = "",
  style,
  mobile,
  title,
  showButton
}) => {
  const [rangeDisabledExist, setRangeDisabled] = useState<boolean>(false);
  const [noOfMonth] = useState<number>(noMonth);
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [nShow, setNShow] = useState([
    {
      m: dates.checkin
        ? typeof dates.checkin === "object"
          ? dates.checkin.getMonth()
          : null
        : new Date().getMonth(),
      y: dates.checkin
        ? typeof dates.checkin === "object"
          ? dates.checkin.getFullYear()
          : null
        : new Date().getFullYear()
    }
  ]);

  useEffect(() => {
    const arr = [...nShow];
    const condition = true;
    while (condition) {
      if (arr.length === noOfMonth) {
        break;
      }
      if (arr[arr.length - 1].m === 11) {
        arr.push({
          m: 0,
          y: arr[arr.length - 1].y + 1
        });
      } else {
        arr.push({
          m: arr[arr.length - 1].m + 1,
          y: arr[arr.length - 1].y
        });
      }
    }
    setNShow(arr);
  }, [noOfMonth]);
  const mob = mobile;

  const nextMonths = () => {
    const newArr = nShow.map((ele) => {
      if (ele.m === 11) {
        return {
          m: 0,
          y: ele.y + 1
        };
      }
      return {
        m: ele.m + 1,
        y: ele.y
      };
    });
    setNShow(newArr);
  };
  const prevMonths = () => {
    const newArr = nShow.map((ele) => {
      if (ele.m === 0) {
        return {
          m: 11,
          y: ele.y - 1
        };
      }
      return {
        m: ele.m - 1,
        y: ele.y
      };
    });
    setNShow(newArr);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight + 10;
    if (bottom && mob) {
      const arr = [];
      if (nShow[nShow.length - 1].m === 11) {
        arr.push({
          m: 0,
          y: nShow[nShow.length - 1].y + 1
        });
      } else {
        arr.push({
          m: nShow[nShow.length - 1].m + 1,
          y: nShow[nShow.length - 1].y
        });
      }

      setNShow([...nShow, ...arr]);
    }
  };

  return (
    <div
      className={"date-picker " + className}
      style={{ height: mob ? "auto" : "" }}
      tabIndex={0}
      id="date-picker">
      {mob ? (
        <></>
      ) : (
        <div className="titleContainer">
          <div className="titleText">{title}</div>
        </div>
      )}
      {children}
      {open && (
        <div
          className="date-picker-wrapper"
          onScroll={handleScroll}
          style={{
            display: "flex",
            gap: mob ? "7px" : "20px",
            width: mob ? "100vw" : "fit-content",
            maxWidth: mob ? "340px" : "",
            flexDirection: mob ? "column" : "row",
            background: "#FFFFFF",
            boxSizing: "border-box",
            boxShadow: !mob && "0px 4px 4px rgba(0, 0, 0, 0.1);",
            borderRadius: mob ? "10px 10px 0 0" : "10px",
            padding: mob ? "8px 0 " : "20px 40px",
            alignItems: mob && "center",
            overflowY: mob ? "auto" : "visible",
            overflowX: "hidden",
            maxHeight: mob && "520px",
            ...style
          }}>
          {!mob && (
            <React.Fragment>
              <LeftButton onClick={prevMonths}>
                <LeftArrow />
              </LeftButton>
            </React.Fragment>
          )}
          {nShow.slice(!mob ? nShow.length - 2 : 0, nShow.length).map((ele) => {
            return (
              <Months
                setDates={setDates}
                disabledDays={disabledDays}
                month={ele.m}
                year={ele.y}
                dates={dates}
                key={`${ele.m}+${ele.y}`}
                mob={mob}
                disabledInRange={setRangeDisabled}
                disableState={rangeDisabledExist}
              />
            );
          })}
          {!mob && (
            <React.Fragment>
              <RightButton onClick={nextMonths}>
                <RightArrow />
              </RightButton>
            </React.Fragment>
          )}
        </div>
      )}
      {showButton ? (
        <a href={"tel:+40741527091"} className="href">
          <div
            onClick={() => {
              showPhoneNumber
                ? navigator.clipboard.writeText("+40 741 527 091")
                : setShowPhoneNumber(true);
            }}
            className="reserveButton">
            {showPhoneNumber ? "+40 741 527 091" : "Rezerva Acum"}
          </div>
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};
const computeDays = (date1: Date, date2: Date) => {
  const diffTime = Number(date1) - Number(date2);
  if (diffTime >= 0) {
    return 0;
  } else {
    const totalDays = Math.ceil(Math.abs(diffTime) / (1000 * 60 * 60 * 24));

    return totalDays;
  }
};
const getEarliestDate = (inputDate: Date, dataArr: DisabledDays[]) => {
  let earliest;

  for (const el of dataArr) {
    const dateL = new Date(`${el.year}-${el.month}-${el.day}`);
    if (inputDate.getTime() < dateL.getTime()) {
      earliest = dateL;
      break;
    }
  }
  if (earliest === null) {
    return inputDate;
  } else return earliest;
};
const isFirstDayDisabled = (disabledDays: DisabledDays[], month: number, year: number) => {
  const daysInM = getDaysInMonth(month, year);
  let firstDate;
  daysInM.data.forEach((element: daysInMDateType) => {
    if ("date" in element) {
      disabledDays.forEach((item: DisabledDays) => {
        if (item.day === element.date && item.month === Number(month) + 2) {
          element.active = false;
        }
      });
    }
  });

  for (const item of daysInM.data) {
    if (typeof item !== "number") {
      firstDate = item.active;
      break;
    }
  }

  return !firstDate;
};
const Months = ({
  dates,
  setDates,
  disabledDays,
  month = new Date().getMonth(),
  year = new Date().getFullYear(),
  disabledInRange,
  disableState
}: MonthsProps) => {
  const { t } = useTranslation();
  const [changeMonth] = useState({
    month: month,
    year: year
  });
  const [data, setData] = useState<dataPro>(getDaysInMonth(changeMonth.month, changeMonth.year));
  data.data.forEach((element: daysInMDateType) => {
    disabledDays.forEach((item) => {
      if (item.day === element.date && item.month === Number(changeMonth.month + 1)) {
        element.active = false;
      }
    });
  });
  useMemo(() => {
    setData(getDaysInMonth(changeMonth.month, changeMonth.year));
  }, [changeMonth]);

  useMemo(() => {
    if (new Date(dates.checkin) > new Date(dates.checkout)) {
      return setDates({
        checkin: dates.checkout,
        checkout: dates.checkin
      });
    }

    if (data.data) {
      const arr = data.data.map((i: daysInMDateType) => {
        if (
          new Date(i.time).getTime() === new Date(dates.checkin).getTime() ||
          new Date(i.time).getTime() === new Date(dates.checkout).getTime()
        ) {
          return { ...i, color: disableState ? "#e2354c" : "#fd8298" };
        } else if (dates.checkin < i.time && dates.checkout > i.time) {
          return { ...i, color: disableState ? "#ff96a8" : "#ffc4cf" };
        } else return { ...i, color: "" };
      });
      setData({ month: data.month, data: arr });
    }
  }, [dates, disableState]);

  useEffect(() => {
    if (dates.checkin === "" && dates.checkout === "") {
      setData(getDaysInMonth(changeMonth.month, changeMonth.year));
    } else {
      let firstDisabledOccured: boolean;

      data.data.forEach((element: daysInMDateType) => {
        typeof dates.checkin === "object" ? dates.checkin.setHours(3, 0, 0) : null;

        if (dates.checkin) {
          if (!element.active && firstDisabledOccured)
            if (!element.active) {
              firstDisabledOccured = true;
            }
          if (
            computeDays(typeof dates.checkin === "object" ? dates.checkin : null, element.time) <= 0
          ) {
            element.active = false;
          }
          const earliestDate = getEarliestDate(
            typeof dates.checkin === "object" ? dates.checkin : null,
            disabledDays
          );
          if (earliestDate !== undefined) {
            if (!computeDays(element.time, earliestDate)) {
              element.active = false;
            }
          }
        }
      });
    }
  }, [dates.checkin, dates.checkout]);

  useEffect(() => {
    if (data.data) {
      const arr = data.data.map((i: daysInMDateType) => {
        if (
          i.time &&
          (i.time.toDateString() ===
            (dates.checkin && typeof dates.checkin === "object"
              ? dates.checkin.toDateString()
              : null) ||
            i.time.toDateString() ===
              (dates.checkout && typeof dates.checkout === "object"
                ? dates.checkout.toDateString()
                : null))
        ) {
          return { ...i, color: "#fd8298" };
        } else if (dates.checkin < i.time && dates.checkout > i.time) {
          return { ...i, color: "#FFC4CF" };
        } else if (i.time && (i.time === dates.checkin || i.time === dates.checkout)) {
          return { ...i, color: "#fd8298" };
        } else return { ...i, color: "" };
      });
      setData({ month: data.month, data: arr });
    }
  }, []);
  const getDaysArray = (start: Date, end: Date) => {
    let arr = [];
    let dt;
    for (arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  useEffect(() => {
    const rangeDays = getDaysArray(new Date(dates.checkin), new Date(dates.checkout));
    rangeDays.map((v) => v.toISOString().slice(0, 10)).join("");
    const rangeDisabled = disabledDays.map(
      (item) => new Date(`${item.year}-${item.month}-${item.day} 0:0:0`)
    );

    let disabledInRangeLocal = false;

    rangeDays.forEach((item) => {
      rangeDisabled.forEach((disabledItem) => {
        if (item.getDate() === disabledItem.getDate()) {
          if (item.getMonth() === disabledItem.getMonth()) {
            disabledInRangeLocal = true;
          }
        }
      });
    });

    disabledInRange(disabledInRangeLocal);
  }, [dates.checkout]);

  const getElementStyle = (ele: daysInMDateType, indx: number, elements: { active: boolean }[]) => {
    const textDecorationInput =
      ele.active || new Date(ele.time).getTime() === new Date(dates.checkin).getTime()
        ? ""
        : "line-through 2px";
    const backgroundInput = ele.color;
    const colorInput =
      (elements[indx + 1] != null && !elements[indx + 1].active && dates.checkin == "") ||
      (elements[indx + 1] == null
        ? isFirstDayDisabled(disabledDays, ele.time.getMonth(), ele.time.getFullYear()) &&
          dates.checkin == ""
          ? "#a9a9a9"
          : ""
        : "")
        ? "#a9a9a9"
        : ele.color
        ? ele.color === "#ffc4cf"
          ? "white"
          : "white"
        : !ele.active
        ? "#979EAB"
        : "#00739E";
    return {
      textDecoration: textDecorationInput,
      background: backgroundInput,
      color: colorInput
    };
  };

  const renderDayClassStyle = (
    ele: daysInMDateType,
    indx: number,
    elements: { active: boolean }[]
  ) => {
    return (ele.active === true &&
      elements[indx + 1] != null &&
      !elements[indx + 1].active &&
      dates.checkin == "") ||
      (elements[indx + 1] == null
        ? isFirstDayDisabled(disabledDays, ele.time.getMonth(), ele.time.getFullYear())
          ? "popupCal"
          : ""
        : "")
      ? "popupCal"
      : "";
  };

  const onClickHandler = (ele: daysInMDateType, indx: number, elements: { active: boolean }[]) => {
    if (ele.active) {
      if (dates.checkin == "" && !elements[indx + 1].active) {
        return;
      }

      if (dates.checkin < dates.checkout) {
        return setDates({
          checkin: "",
          checkout: ""
        });
      }
      if (dates.checkout === ele.time || dates.checkin === ele.time) {
        return setDates({
          checkin: "",
          checkout: ""
        });
      }
      if (dates.checkin === "") {
        setDates({
          ...dates,
          checkin: ele.time
        });
      } else if (dates.checkin !== "") {
        setDates({
          ...dates,
          checkout: ele.time
        });
      }
    } else {
      if (dates.checkin != "" && dates.checkout != "") {
        return setDates({
          checkin: "",
          checkout: ""
        });
      } else if (ele.time === dates.checkin || ele.time === dates.checkout) {
        return setDates({
          checkin: "",
          checkout: ""
        });
      }
    }
  };

  return (
    <MonthWrapper>
      <MonthTitle>
        {data.data && (
          <H3>
            {data.month}
            &nbsp;{year}
          </H3>
        )}
      </MonthTitle>
      <WeekDaysWrapper>
        {Object.values(componentStrings.bookingComponent.calendar.daysOfWeek).map((item, index) => (
          <div key={index} className="monthDays">
            {item}
          </div>
        ))}
      </WeekDaysWrapper>
      <MonthContainer>
        {data.data &&
          data.data.map((ele: daysInMDateType, indx: number, elements: daysInMDateType[]) => {
            return (
              <DayContainer
                title={t(bookingComponent.calendar.onlyCheckOut)}
                classStyle={renderDayClassStyle(ele, indx, elements)}
                style={getElementStyle(ele, indx, elements)}
                onClick={onClickHandler.bind(this, ele, indx, elements)}
                key={`${ele.date}-${ele.day}-${indx}`}>
                <span>{ele.date}</span>
              </DayContainer>
            );
          })}
      </MonthContainer>
    </MonthWrapper>
  );
};

const monthNames = [
  "Ianuarie",
  "Februarie",
  "Martie",
  "Aprile",
  "Mai",
  "Iunie",
  "Iulie",
  "August",
  "Septembrie",
  "Octombrie",
  "Noiembrie",
  "Decembrie"
];

function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  const arr = days.map((ele) => {
    if (new Date() > ele) {
      return {
        active: false,
        day: new Date(ele).getDay(),
        date: new Date(ele).getDate(),
        time: new Date(ele)
      };
    }
    return {
      active: true,
      day: new Date(ele).getDay(),
      date: new Date(ele).getDate(),
      time: new Date(ele)
    };
  });
  const fillerArr = new Array(7).fill({
    active: false
  });
  const fillArr: daysInMDateType[] = [...fillerArr.slice(0, arr[0].time.getDay()), ...arr];
  return { month: monthNames[month], data: fillArr };
}
