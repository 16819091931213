import images from '../data/images';

export const navMenu = {
  items: {
    home: { text: 'Navbar.navItems.home.text', link: 'Navbar.navItems.home.link' },
    aboutUs: { text: 'Navbar.navItems.aboutUs.text', link: 'Navbar.navItems.aboutUs.link' },
    reviews: { text: 'Navbar.navItems.reviews.text', link: 'Navbar.navItems.reviews.link' },
    gallery: { text: 'Navbar.navItems.gallery.text', link: 'Navbar.navItems.gallery.link' },
    offers: { text: 'Navbar.navItems.offers.text', link: 'Navbar.navItems.offers.link' },
    bookings: { text: 'Navbar.navItems.bookings.text', link: 'Navbar.navItems.bookings.link' },
    contactUs: { text: 'Navbar.navItems.contactUs.text', link: 'Navbar.navItems.contactUs.link' }
  }
};

export const firstPage = {
  arraySlide: {
    studio: {
      title: 'FirstPage.arraySlide.studio.title',
      description: 'FirstPage.arraySlide.studio.description'
    },
    view: {
      title: 'FirstPage.arraySlide.view.title',
      description: 'FirstPage.arraySlide.view.description'
    },
    relax: {
      title: 'FirstPage.arraySlide.view.title',
      description: 'FirstPage.arraySlide.view.description'
    }
  },
  reservationButton: 'FirstPage.reservationButton',
  newsletterButton: 'FirstPage.newsletterButton',

  contactData: {
    phone: 'tel: 0040 722 567 890',
    email: 'mailto: contact@romanticstudios.com'
  }
};

export const bookingComponent = {
  title: 'bookingComponent.title',
  bookNow: 'bookingComponent.bookNow',
  topMessage: 'bookingComponent.topMessage',
  bottomMessage: 'bookingComponent.bottomMessage',
  formTopMessage: 'bookingComponent.formTopMessage',
  formBottomMessage: 'bookingComponent.formBottomMessage',
  days: {
    single: 'bookingComponent.days.single',
    more: 'bookingComponent.days.more'
  },
  packDescription: 'bookingComponent.packDescription',
  persons: {
    single: 'bookingComponent.persons.single',
    more: 'bookingComponent.persons.more'
  },
  currency: 'bookingComponent.currency',
  price: 'bookingComponent.price',
  bookNowText: 'bookingComponent.bookNowText',
  cancelBook: 'bookingComponent.cancelBook',
  continueText: 'bookingComponent.continueText',
  confirmText: 'bookingComponent.confirmText',
  goBack: 'bookingComponent.goBack',
  extraServices: 'bookingComponent.extraServices',
  servicesPack: 'bookingComponent.servicesPack',
  stepsBooking: {
    book: 'bookingComponent.stepsBooking.book',
    details: 'bookingComponent.stepsBooking.details',
    confirm: 'bookingComponent.stepsBooking.confirm',
    payment: 'bookingComponent.stepsBooking.payment'
  },
  formInputText: {
    firstName: 'bookingComponent.formInputText.firstName',
    lastName: 'bookingComponent.formInputText.lastName',
    email: 'bookingComponent.formInputText.email',
    phone: 'bookingComponent.formInputText.phone',
    detailsText: 'bookingComponent.formInputText.detailsText'
  },
  sericePack: {
    relaxPack: { name: 'bookingComponent.sericePack.relaxPack.name' },
    romanticPack: { name: 'bookingComponent.sericePack.relaxPack.name' },
    lovelyPack: { name: 'bookingComponent.sericePack.relaxPack.name' }
  },
  packMessage: {
    containsPack: "bookingComponent.packDescription"
  },
  datePicker: {
    checkInLabel: 'bookingComponent.datePicker.checkInLabel',
    checkOutLabel: 'bookingComponent.datePicker.checkOutLabel',
    checkinCheckOutMobile: 'bookingComponent.datePicker.checkinCheckOutMobile',
    personsLabel: 'bookingComponent.datePicker.personsLabel',
    daysLabel: 'bookingComponent.datePicker.daysLabel'
  },
  extraServicesList: {
    wine: "bookingComponent.extraServicesList.wine",
    roses: "bookingComponent.extraServicesList.roses",
    sweets: "bookingComponent.extraServicesList.sweets",
    breakfast: "bookingComponent.extraServicesList.breakfast",
    romanticdecor: "bookingComponent.extraServicesList.romanticdecor",
    fruitplate: "bookingComponent.extraServicesList.fruitplate",
    cake: "bookingComponent.extraServicesList.cake",
    photosession: "bookingComponent.extraServicesList.photosession",
    cheeseplate: "bookingComponent.extraServicesList.cheeseplate",
    surprisecake: "bookingComponent.extraServicesList.surprisecake"
  },
  offerDetailsIncluded: {
    included: "bookingComponent.packDescription"
  },
  offerList: {
    relaxPack: "bookingComponent.sericePack.relaxPack.detailsIncluded",
    lovelyPack: "bookingComponent.sericePack.lovelyPack.detailsIncluded",
    romanticPack: "bookingComponent.sericePack.romanticPack.detailsIncluded"
  },

  bookingOverview: {
    name: 'bookingComponent.bookingOverview.name',
    email: 'bookingComponent.bookingOverview.email',
    phone: 'bookingComponent.bookingOverview.phone',
    otherDetails: 'bookingComponent.bookingOverview.otherDetails',
    bookedRange: 'bookingComponent.bookingOverview.bookedRange',
    offersPack: 'bookingComponent.bookingOverview.offersPack',
    extraServices: 'bookingComponent.bookingOverview.extraServices'
  },
  calendar: {
    onlyCheckOut: 'bookingComponent.calendar.onlyCheckOut',
    daysOfWeek: {
      sunday: 'bookingComponent.calendar.daysOfWeek.sunday',
      monday: 'bookingComponent.calendar.daysOfWeek.monday',
      tuesday: 'bookingComponent.calendar.daysOfWeek.tuesday',
      wednesday: 'bookingComponent.calendar.daysOfWeek.wednesday',
      thursday: 'bookingComponent.calendar.daysOfWeek.thursday',
      friday: 'bookingComponent.calendar.daysOfWeek.friday',
      saturday: 'bookingComponent.calendar.daysOfWeek.saturday'
    }
  },
  paymentStatus: {
    succes: 'bookingComponent.paymentStatus.succes',
    denied: 'bookingComponent.paymentStatus.denied'
  }
};
export const ReviewsConst = {
  topMessage: 'Reviews.topMessage',
  bottomMessage: 'Reviews.bottomMessage'
};
export const whyUs = {
  topMessage: "whyUs.topMessage",
  bottomMessage: "whyUs.bottomMessage",
  bigAnswer: "whyUs.bigAnswer",
  facitiliesTextHover: {
    netflix: "whyUs.facitiliesTextHover.netflix",
    panoramic: "whyUs.facitiliesTextHover.panoramic",
    baldachin: "whyUs.facitiliesTextHover.baldachin",
    wineHouse: "whyUs.facitiliesTextHover.wineHouse",
    aircondiiton: "whyUs.facitiliesTextHover.aircondiiton",
    jacuzzi: "whyUs.facitiliesTextHover.jacuzzi",
    toiletries: "whyUs.facitiliesTextHover.toiletries",
    minibar: "whyUs.facitiliesTextHover.minibar"
  }
};

export const reviewsRating = {
  booking: 'ReviewsRating.booking',
  airbnb: 'ReviewsRating.airbnb',
  bookingScore: 'ReviewsRating.bookingScore',
  airbnbScore: 'ReviewsRating.airbnbScore',
  bookingOutOff: 'ReviewsRating.bookingOutOff',
  airbnbOutOff: 'ReviewsRating.airbnbOutOff'
};

export const FAQBlockConst = {
  topMessage: 'FAQ.topText',
  bottomMessage: 'FAQ.bottomText',
  questionList: {
    questionOne: {
      question: 'FAQ.questionList.questionOne.question',
      answer: 'FAQ.questionList.questionOne.answer'
    },
    questionTwo: {
      question: 'FAQ.questionList.questionTwo.question',
      answer: 'FAQ.questionList.questionTwo.answer'
    },
    questionThree: {
      question: 'FAQ.questionList.questionThree.question',
      answer: 'FAQ.questionList.questionThree.answer'
    },
    questionFour: {
      question: 'FAQ.questionList.questionFour.question',
      answer: 'FAQ.questionList.questionFour.answer'
    },
    questionFive: {
      question: 'FAQ.questionList.questionFive.question',
      answer: 'FAQ.questionList.questionFive.answer'
    }
  }
};
export const OffersConst = {
  topText: "Offers.topText",
  bottomText: "Offers.bottomText",

  offers: {
    featuresList: {
      nightStay: "Offers.featuresList.nightStay",
      spaceTop: "+",
      sweetSurprise: "Offers.featuresList.sweetSurprise",
      wine: "Offers.featuresList.wine",
      maps: "Offers.featuresList.maps",
      bathAccesories: "Offers.featuresList.bathAccesories",
      miniBar: "Offers.featuresList.miniBar",
      spaceDown: "+",
      flowers: "Offers.featuresList.flowers",
      romanticDesign: "Offers.featuresList.romanticDesign",
      space: "+",
      escapeRoom: "Offers.featuresList.escapeRoom",
      cake: "Offers.featuresList.cake"
    }
  },
  callToAction: "Offers.callToAction",
  pricesInfo: "Offers.pricesInfo"
};

export const footerConstants = {
  footerItemList: {
    home: { text: 'Footer.itemList.home', link: 'Navbar.navItems.home.link' },
    aboutUs: { text: 'Footer.itemList.aboutUs', link: 'Navbar.navItems.aboutUs.link' },
    reviews: { text: 'Footer.itemList.reviews', link: 'Navbar.navItems.reviews.link' },
    gallery: { text: 'Footer.itemList.gallery', link: 'Navbar.navItems.gallery.link' },
    offers: { text: 'Footer.itemList.offers', link: 'Navbar.navItems.offers.link' },
    bookings: { text: 'Footer.itemList.bookings', link: 'Navbar.navItems.bookings.link' },
    contactUs: { text: 'Footer.itemList.contactUs', link: 'Navbar.navItems.contactUs.link' }
  },

  address: {
    locationAddress: 'Footer.address.locationAddress',
    phone: 'Footer.address.phone',
    email: 'Footer.address.email'
  },
  copyrightStuff: {
    copyText: 'Footer.copyrightStuff.copyText'
  },
  socialData: {
    facebook: {
      icon: images.socialIcons.facebookIcon,
      link: "https://www.facebook.com/romanticstudiobv"
    },
    instagram: {
      icon: images.socialIcons.instagramIcon,
      link: "https://www.instagram.com/romanticstudiobrasov/"
    }
  }
};
export const cookies = {
  headTitle: 'Cookie.headTitle',
  mainText: 'Cookie.mainText',
  secondText: 'Cookie.secondText',
  acceptButton: 'Cookie.acceptButton'
};

export const offersPackPricing = {
  relaxOfferPack: {
    weekdaysPrice: 350,
    weekendsPrice: 450
  },
  lovelyOfferPack: {
    weekdaysPrice: 600,
    weekendsPrice: 700
  },
  romanticOfferPack: {
    weekdaysPrice: 1000,
    weekendsPrice: 1100
  }
};

export const Weekdays = {
  monday: "WeekDays.monday",
  tuesday: "WeekDays.tuesday",
  wednesday: "WeekDays.wednesday",
  thursday: "WeekDays.thursday",
  friday: "WeekDays.friday",
  saturday: "WeekDays.saturday",
  sunday: "WeekDays.sunday"
};

export const messengerChat = {
  loggedInMessage: "messengerChat.loggedInMessage",
  loggedOutMessage: "messengerChat.loggedOutMessage"
};
