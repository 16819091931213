import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCuTR50Na_oUqEZUHaK70sSRQ03O8_qrqw",
  authDomain: "brasovromanticapartment.firebaseapp.com",
  projectId: "brasovromanticapartment",
  storageBucket: "brasovromanticapartment.appspot.com",
  messagingSenderId: "153923677890",
  appId: "1:153923677890:web:9d64d241a7dfe7ade5155b",
  measurementId: "G-M68HD4R5TH"
};

const app = initializeApp(firebaseConfig);

export default app;
