import React from "react";
import styles from "./OfferCard.module.scss";
import { useTranslation } from "react-i18next";
import { componentStrings } from "../data/strings";
interface IProps {
  setBookingPop: (bookingPop: boolean) => void;
  offerChoosed: (offerIndex: string) => void;
  title: string;
  price: number;
  currency: string;
  packIncludes: object;
  activeIndex: number;
  index: number;
  cardName: string;
}

const OfferCard: React.FC<IProps> = ({
  cardName,
  title,
  price,
  currency,
  packIncludes,
  activeIndex,
  index,
  setBookingPop,
  offerChoosed
}) => {
  const { t } = useTranslation();
  const addOption = () => {
    setBookingPop(true);
    offerChoosed(cardName);
  };

  return (
    <div className={activeIndex === index ? styles.offerCardboard : styles.offerCardboardSecond}>
      <div className={styles.topArea}>
        <h3 className={activeIndex === index ? styles.title : styles.titleSecond}>{title}</h3>
      </div>

      <div className={styles.middleArea}>
        <div className={activeIndex === index ? styles.fineLine : styles.fineLinePink}></div>
        <h3 className={activeIndex === index ? styles.price : styles.pricePink}>{price}</h3>
        <h4 className={activeIndex === index ? styles.currency : styles.currencySecond}>
          {currency}
        </h4>
        <ul className={styles.ulList}>
          {Object.values(packIncludes).map((item, index) => (
            <li key={index} className={styles.liItem}>
              {t(item)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OfferCard;
