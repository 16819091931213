import {
  OffersConst,
  offersPackPricing,
  Weekdays,
  bookingComponent
} from "../constants/stringConst";
export const componentStrings = {
  navMenu: {
    aboutus: { text: "Despre noi", link: "#desprenoi" },
    reviews: { text: "Recenzii", link: "#reviews" },
    gallery: { text: "Galerie", link: "#gallery" },
    offers: { text: "Oferte", link: "#offers" },
    bookings: { text: "Rezervari", link: "#booking" },
    contact: { text: "Contact", link: "#contactus" }
  },
  mobileTitle: "Romantic Studio Apartment",
  bookingComponent: {
    topMessage: "Rezerva",
    bottomMessage: "Acum!",
    currency: "RON",
    price: "Pret",
    bookNowText: "Rezerva",
    formTopMessage: "Formular",
    formBottomMessage: "Rezervare",
    days: { single: "zi", more: "zile" },
    persons: {
      single: "persoana",
      more: "persoane"
    },
    cancelBook: "Anulare",
    continueText: "Continua",
    confirmText: "Confirma",
    goBack: "Inapoi",
    extraServices: "Servicii extra",
    servicesPack: "Pachet Servicii",
    formInputText: {
      firstName: "Nume",
      lastName: "Prenume",
      email: "Email",
      phone: "Telefon",
      detailsText: "Precizari"
    },
    sericePack: {
      relaxPack: {
        name: `Let's studio`,
        price: 100,
        details: bookingComponent.offerList.relaxPack
      },
      romanticPack: {
        name: "Love potion",
        price: 200,
        details: bookingComponent.offerList.romanticPack
      },
      lovelyPack: {
        name: "Our moment",
        price: 300,
        details: bookingComponent.offerList.lovelyPack
      }
    },
    offerDetailsIncluded: {
      included: bookingComponent.offerDetailsIncluded.included
    },
    infoCard: {
      infoType: "Info",
      mainText: "Acest pachet contine 2 nopti de cazare!",
      detailsText:
        "Va rugam sa va asigurati ca datele selectate sunt pe o perioada de cel putin 2 nopti, in caz contrar acest lucru se va face automat",
      buttonAction: "Inapoi la calendar"
    },
    datePicker: {
      checkInLabel: "Check in",
      checkOutLabel: "Check out",
      checkinCheckOutMobile: "Selecteaza perioada",
      personsLabel: "Persoane",
      daysLabel: "Zile"
    },
    extraServicesList: {
      wine: { name: bookingComponent.extraServicesList.wine, price: 30 },
      roses: { name: bookingComponent.extraServicesList.roses, price: 80 },
      sweets: { name: bookingComponent.extraServicesList.sweets, price: 50 },
      breakfast: { name: bookingComponent.extraServicesList.breakfast, price: 50 },
      romanticdecor: { name: bookingComponent.extraServicesList.romanticdecor, price: 30 },
      fruitplate: { name: bookingComponent.extraServicesList.fruitplate, price: 50 },
      cake: { name: bookingComponent.extraServicesList.cake, price: 25 },
      photosession: { name: bookingComponent.extraServicesList.photosession, price: 34 },
      cheeseplate: { name: bookingComponent.extraServicesList.cheeseplate, price: 35 },
      surprisecake: { name: bookingComponent.extraServicesList.surprisecake, price: 35 }
    },
    bookingOverview: {
      name: "Nume",
      email: "Email",
      phone: "Telefon",
      otherDetails: "Alte detalii",
      bookedRange: "Perioada rezervare",
      offersPack: "Pachet oferte",
      extraServices: "Servicii extra"
    },
    calendar: {
      daysOfWeek: {
        sunday: "Dum",
        monday: "Lun",
        tuesday: "Mar",
        wednesday: "Mie",
        thursday: "Joi",
        friday: "Vin",
        saturday: "Sam"
      }
    },
    paymentStatus: {
      succes: "Plata inregistrata",
      denied: "Plata respinsa "
    }
  },
  WhyUs: {
    topText: "De ce",
    bottomText: "Noi?",
    theAnswer:
      "Pentru că vrei să simți că iubești cu adevărat. Pentru că vrei să petreci cele mai frumoase momente lângă persoana iubită! Îmbină atmosfera unui oraș frumos cu amintiri minunate într-o aventură romantică și relaxantă. Nu e doar o cazare, e momentul vostru!",
    facitiliesTextHover: {
      netflix: "Netflix",
      panoramic: "Panoramic",
      baldachin: "Baldachin",
      wineHouse: "Vinul casei",
      aircondiiton: "AC",
      jacuzii: "Jacuzii",
      toiletries: "Toiletries",
      minibar: "Minibar"
    }
  },
  OffersCards: {
    topText: "Oferte",
    bottomText: "Pentru Cupluri Indraznete",

    offers: {
      relaxPack: {
        cardName: "relaxPack",
        title: "Let's studio",
        price: 349,
        currency: "RON",
        featuresList: {
          nightStay: OffersConst.offers.featuresList.nightStay,
          spaceTop: "+",
          sweetSurprise: OffersConst.offers.featuresList.sweetSurprise,
          wine: OffersConst.offers.featuresList.wine,
          maps: OffersConst.offers.featuresList.maps,
          bathAccesories: OffersConst.offers.featuresList.bathAccesories,
          miniBar: OffersConst.offers.featuresList.miniBar
        }
      },
      romanticPack: {
        cardName: "romanticPack",
        title: "Love potion",
        price: 599,
        currency: "RON",
        featuresList: {
          nightStay: OffersConst.offers.featuresList.nightStay,
          spaceTop: "+",
          sweetSurprise: OffersConst.offers.featuresList.sweetSurprise,
          wine: OffersConst.offers.featuresList.wine,
          maps: OffersConst.offers.featuresList.maps,
          bathAccesories: OffersConst.offers.featuresList.bathAccesories,
          miniBar: OffersConst.offers.featuresList.miniBar,
          spaceDown: "+",
          flowers: OffersConst.offers.featuresList.flowers,
          romanticDesign: OffersConst.offers.featuresList.romanticDesign
        }
      },
      lovelyPack: {
        cardName: "lovelyPack",
        title: "Our moment",
        price: 1199,
        currency: "RON",
        featuresList: {
          nightStay: OffersConst.offers.featuresList.nightStay,
          spaceTop: "+",
          sweetSurprise: OffersConst.offers.featuresList.sweetSurprise,
          wine: OffersConst.offers.featuresList.wine,
          maps: OffersConst.offers.featuresList.maps,
          bathAccesories: OffersConst.offers.featuresList.bathAccesories,
          miniBar: OffersConst.offers.featuresList.miniBar,
          spaceDown: "+",
          escapeRoom: OffersConst.offers.featuresList.escapeRoom,
          flowers: OffersConst.offers.featuresList.flowers,
          cake: OffersConst.offers.featuresList.cake,
          romanticDesign: OffersConst.offers.featuresList.romanticDesign
        }
      }
    },
    callToAction: OffersConst.callToAction,
    pricesInfo: OffersConst.pricesInfo
  },
  Reviews: {
    topText: "Recenzii",
    bottomText: "Oaspetii nostri",

    clientsList: [
      {
        author: "Daniel",
        stars: "5",
        textReview:
          "Thank you for a lovely stay, the place is definitely romantic, the small touches are making the difference, very clean and cozy with lots of amenities provided. The apartment has got a great view of Tampa while enjoying the jacuzzi and it is very well situated, close to shopping centres, restaurants, market, bus and train station. Alex has been a great host, we've had a great communication and he also made recommendations. Very well deserved five stars, a place we'll definitely come back!"
      },
      {
        author: "Aarzu",
        stars: "5",
        textReview:
          "Great place and exactly as described. The apartment is across the street from both the train station and a couple bus lines that go straight into the old city."
      },
      {
        author: "Irina",
        stars: "5",
        textReview:
          "Amazing view, the fairy lights give an awesome vibe to the room. Jacuzzi is in impeccable condition. Best bed I've ever slept in. The bar is perfect for late night conversations. The mini kitchen holds everything you would need for a trip."
      },
      {
        author: "Andreea",
        stars: "5",
        textReview:
          "The apartment was well maintained and the location is beautiful, we communicated well with the owner. We will definitely come back soon!"
      },
      {
        author: "Ayesha",
        stars: "5",
        textReview:
          "The Romantic Studio is the perfect place for couples to stay in Brasov. We had such a lovely time here; the mountain view is beautiful to wake up to, the jacuzzi was amazing to relax in each evening and the decoration really did create a romantic atmosphere."
      },
      {
        author: "Raluca",
        stars: "5",
        textReview:
          "The perfect place to spent time and relax. Good location in Brasov. Nice view from the hottub. The best host!"
      },
      {
        author: "Georgiana Marina",
        stars: "5",
        textReview:
          "Great studio. We have enjoyed our evening. The studio is very comfy, fully equipped with the necessary, it was cleaned and the host is very nice. I recommend it."
      },
      {
        author: "Sebastian",
        stars: "5",
        textReview:
          "Alex has definitely invested a lot of heart and soul in this location. The place is absolutely perfect for a couple to enjoy some quality time. highly recommended in my perspective. tasteful, pristine cleanliness and a very convenient location close to everything you need."
      },
      {
        author: "Leo",
        stars: "5",
        textReview:
          "I totally recommend this place for anybody who wants to have a romantic and relaxed weekend. Alex from the begining had an outstanding willignes to help me create the perfect enviorement and to prepare a surprise."
      },
      {
        author: "Forij",
        stars: "5",
        textReview:
          "Romantic studio says it all! Perfect to stay with your partner, you can expect a romantic, clean and beautiful room with a breathtaking view over the mountain from your jacuzzi. p.s. it looks better than the photos!"
      },
      {
        author: "Roodolph",
        stars: "5",
        textReview:
          "We booked late in the day. Alex replied immediately and was out of town at the time but agreed we could have a later check out. The room was amazing, great shower, and cute little kitchen, with everything you need. The views from the hot tub are so nice."
      },
      {
        author: "Gabriel",
        stars: "5",
        textReview:
          "Thank you for the hospitality! It was a nice and clean place with a special view and very good jacuzzi."
      },
      {
        author: "Michele",
        stars: "5",
        textReview:
          "Everything was great, the bathroom nice and clean, full equipped kitchen and amazing massage bathtub."
      }
    ]
  },
  firstPage: {
    studio: {
      title: "ROMANTIC STUDIO APARTMENT",
      description: "Timp pentru voi, timp in doi!"
    },
    view: {
      title: "O PRIVELIȘTE SUPERBĂ",
      description: "Unde poti savura cafeaua!"
    },
    relax: {
      title: "MOMENTE DE RELAXARE DEPLINĂ",
      description: "Romantism si poate o sampanie"
    },
    reservationButton: "Rezervă online",
    newsletterButton: "Abonează-te la newsletter"
  },

  ReviewsRating: {
    booking: "Booking.com guest experiences",
    airbnb: "Airbnb.com reviewed as superthost",
    bookingScore: "9.9",
    airbnbScore: "5",
    bookingOutOff: "out of 10",
    airbnbOutOff: "out of 5"
  },

  PhotoGallery: {
    title: "Gallery",
    subTitle: "Romantic"
  },

  FAQ: {
    topText: "FAQ",
    bottomText: "That might help",
    questionList: {
      questionOne: {
        question: "Care este diferența de preț dintre weekend și weekday?",
        answer:
          "Diferenta dintre pretul de weekend si weekday este de 100 RON. De luni pana joi pretul este de 350, iar de vineri pana duminica 450"
      },
      questionTwo: {
        question: "Se pot adauga produse extra?",
        answer:
          "Desigur. Pe langa cele vizibile in casuta pentru rezervare se pot oferi servicii extra la cerere. Alege sa ne contactezi inainte.(Vezi sectiunea Contact) "
      },
      questionThree: {
        question: "Ce obiective turistice se afla in zona Romantic Studio?",
        answer:
          "Oferim oaspetilor nostri o harta friendly, personalizata cu cele mai populare obiective turistice din Brasov si imprejurimi. "
      },
      questionFour: {
        question: "Exista posibilitatea de a inchiria studioul pentru o luna?",
        answer:
          "Inchirierea Romantic Studio Brasov se poate face si pe o perioada mai mare de timp. Pentru rezervari mai mari oaspetii nostri beneficiaza de discount-uri dedicate. "
      },
      questionFive: {
        question: "Cate persoane pot fi cazate intr-o rezervare?",
        answer:
          "Romantic Studio Brasov este un studio cu tematica romantica, amenajat special pentru 2 persoane. "
      },
      questionSix: {
        question: "Pot veni cu animalul de companie in vacanta?",
        answer:
          "Suntem pet friendly in sufletul nostru, insa ne dorim sa avem grija si de oaspetii nostri cu alergie la par de animale. Daca iti doresti cu orice pret sa vii cu prietenul tau blanos in vacanta, te putem ajuta sa gasesti un Pet Hotel in apropiere. "
      }
    }
  },
  Footer: {
    itemList: {
      home: "Home",
      aboutUs: "Despre noi",
      reviews: "Recenzii",
      gallery: "Galerie",
      offers: "Oferte",
      bookings: "Rezervari",
      contactUs: "Contact"
    },
    address: {
      locationAddress: "Bulverdul Garii 20, Brasov 500218, Romania",
      phone: "+40 741 527 091",
      email: "contact@romanticstudios.com"
    },
    copyrightStuff: {
      copyText:
        "Copyright C 2022 | Romantic Studio Brasov | Toate drepturile rezervate | Designed and powered by"
    }
  }
};

export const OffersDetailsText = {
  monday: Weekdays.monday,
  thursday: Weekdays.thursday,
  friday: Weekdays.friday,
  sunday: Weekdays.sunday,

  relaxPackPrice: offersPackPricing.relaxOfferPack.weekdaysPrice,
  relaxPackPriceWeekend: offersPackPricing.relaxOfferPack.weekendsPrice,

  lovelyPackPrice: offersPackPricing.lovelyOfferPack.weekdaysPrice,
  lovelyPackPriceWeekend: offersPackPricing.lovelyOfferPack.weekendsPrice,

  romanticPackPrice: offersPackPricing.romanticOfferPack.weekdaysPrice,
  romanticPackPriceWeekend: offersPackPricing.romanticOfferPack.weekendsPrice
};
