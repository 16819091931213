import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Navbar from "../components/Navbar";
import NewsletterPop from "./../components/NewsletterPop";
import BookingComponent from "./BookingComponent";
import FirstPageCarousel, { CarouselItem } from "../components/FirstPageCarousel";
import { useOnScreen, useOutsideClicker } from "../hooks/onScreen";

import images from "../data/images";
import styles from "./FirstPage.module.scss";
import { firstPageArray } from "../data/arrays";
import { firstPage } from "../constants/stringConst";

const FirstPage = () => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [mailHover, setMailHover] = useState<boolean>(false);
  const [phoneHover, setPhoneHover] = useState<boolean>(false);
  const [newsletterPop, setNewsletterPop] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [bookingPop, setBookingPop] = useState<boolean>(false);
  const photoArray = firstPageArray;
  const ref = useRef(null);
  const backdropRef = useRef(null);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const newsletterHandler = () => {
    setNewsletterPop(!newsletterPop);
    ReactGA.event(`user_event_newsletterPoppedUp`);
  };
  const bookingPopHandler = () => {
    setBookingPop(!bookingPop);
    ReactGA.event(`user_event_bookingPoppedUp`);
  };
  const backdropClose = () => {
    setNewsletterPop(false);
    setBookingPop(false);
  };

  useOutsideClicker(backdropRef, () => {
    backdropClose();
  });
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    handleResize();
  }, []);

  const nextPhoto = () => {
    if (activeIndex > photoArray.length - 3) {
      photoArray.push(firstPageArray[activeIndex - 1]);
    }
  };

  const handleMailHover = () => {
    setMailHover(mailHover ? false : true);
  };

  const handlePhoneHover = () => {
    setPhoneHover(phoneHover ? false : true);
  };

  const eventClick = (eventName: string) => {
    const dictionary = {
      mailButton: "email"
    };
    window.open(firstPage.contactData[dictionary[eventName]]);
    ReactGA.event(`user_event_${eventName}`);
  };

  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_FirstPage");
    }
  }, [isVisible]);

  window.addEventListener("resize", handleResize);

  return (
    <div ref={ref} className={styles.container}>
      <Navbar />
      {newsletterPop && (
        <div ref={backdropRef} className={styles.backDrops}>
          <NewsletterPop close={backdropClose} />
        </div>
      )}
      {bookingPop && (
        <div ref={backdropRef} className={styles.backDrops}>
          <BookingComponent floating={true} close={backdropClose} closeButton={true} />
        </div>
      )}
      <FirstPageCarousel
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        nextPhoto={nextPhoto}
        leftArrow={images.firstPage.leftArrow}
        rightArrow={images.firstPage.rightArrow}
        leftArrowHover={images.firstPage.leftArrowHover}
        rightArrowHover={images.firstPage.rightArrowHover}>
        {photoArray.map((item, index) => {
          return (
            <CarouselItem key={index} width={"100%"}>
              <div
                className={styles.carouselImage}
                style={{
                  backgroundImage: `url(${
                    screenWidth < 576
                      ? item?.imageMobile
                      : screenWidth > 992
                      ? item?.image
                      : item?.imageTablet
                  })`
                }}>
                <div className={styles.textContainer}>
                  <span className={styles.title}>{t(item.title)}</span>
                  <span className={styles.description}>{t(item.description)}</span>
                </div>
              </div>
            </CarouselItem>
          );
        })}
      </FirstPageCarousel>
      {screenWidth > 576 ? (
        <div className={styles.buttonsContainer}>
          <div className={styles.reservationContainer}>
            <img
              className={styles.button}
              onClick={eventClick.bind(this, "mailButton")}
              src={mailHover ? images.firstPage.mailHover : images.firstPage.mail}
              onMouseEnter={screenWidth < 992 ? undefined : handleMailHover}
              onMouseLeave={screenWidth < 992 ? undefined : handleMailHover}
            />

            <div onClick={bookingPopHandler} className={styles.reservationButton}>
              {t(firstPage.reservationButton)}
            </div>
            <img
              className={styles.button}
              onClick={eventClick.bind(this, "phoneButton")}
              src={phoneHover ? images.firstPage.phoneHover : images.firstPage.phone}
              onMouseEnter={screenWidth < 992 ? undefined : handlePhoneHover}
              onMouseLeave={screenWidth < 992 ? undefined : handlePhoneHover}
            />
          </div>
          <div onClick={newsletterHandler} className={styles.newsletterButton}>
            {t(firstPage.newsletterButton)}
          </div>
        </div>
      ) : (
        <div className={styles.buttonsContainer}>
          <div onClick={bookingPopHandler} className={styles.reservationButton}>
            {t(firstPage.reservationButton)}
          </div>
          <div onClick={newsletterHandler} className={styles.newsletterButton}>
            {t(firstPage.newsletterButton)}
          </div>
          <div className={styles.reservationContainer}>
            <img
              className={styles.button}
              src={images.firstPage.mail}
              onClick={eventClick.bind(this, "mailButton")}
            />
            <a href="tel:+40741527091">
              <img className={styles.button} src={images.firstPage.phone} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstPage;
