import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
// import {
//   InputFormProps,
//   DatesProps,
//   BookingProps,
//   CounterInterface,
//   InputBookInterface
// } from "./../utils/interfaceExport/BookingInterface";
import { useOnScreen } from "../hooks/onScreen";

// import InputBooking from "../components/InputBooking";
// import RadioInputs from "../components/RadioInputs";
// import ConfirmOverview from "../components/MobileComponent/ConfirmOverview";
// import BackdropDialog from "../components/BackdropDialog";
// import DatePickerBox from "../components/DatePickerBox";
// import StepGuider from "../components/MobileComponent/StepGuider";
// import DesktopGuide from "./DesktopGuide";
// import PaymentStatus from "../components/PaymentStatus";
import HeadText from "../components/HeadText";
// import { inputsParams } from "../utils/constantsRadioInputs";
import { bookingComponent } from "../constants/stringConst";
import styles from "./../pageblocks/BookingComponent.module.scss";
// import InfoCardboard from "../components/InfoCardboard/InfoCardboard";
import { NewCalendar } from "../components/NewCalendar/NewCalendar";
import { DateType, DisabledDays } from "../utils/interfaceExport/CalendarInterface";
import { getDisabledDays } from "../functions/firebase";
import images from "../data/images";

interface BookingInterface {
  close?: () => void;
  closeButton?: boolean;
  floating?: boolean;
  checkedOptions?: {
    [key: string]: { name: string; price: number };
  };
}

const BookingComponent = ({ floating, close, closeButton }: BookingInterface) => {
  const [dates, setDates] = useState<DateType>({
    checkin: "",
    checkout: ""
  });
  const [showCheckInCalendar] = useState<boolean>(true);
  const [disabledDays, setDisabledDays] = useState<DisabledDays[]>([]);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { t } = useTranslation();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (screenWidth < 877) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (isVisible) {
      ReactGA.event("user_scrollOn_BookingComponent");
    }
  }, [isVisible]);

  useEffect(() => {
    getDisabledDays(setDisabledDays);
  }, [dates]);

  // const [bookingData, setbookingData] = useState<BookingProps>({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phoneNo: "",
  //   otherDetails: "",
  //   choosedOffers: {
  //     servicePack:
  //       checkedOptions != null
  //         ? checkedOptions
  //         : {
  //             [Object.keys(componentStrings.bookingComponent.sericePack)[0]]: Object.values(
  //               componentStrings.bookingComponent.sericePack
  //             )[0]
  //           },
  //     extrasOptions: Object.assign(
  //       {},
  //       ...Object.entries(inputsParams).map(([key]) => {
  //         const temp = {
  //           [key]: {
  //             name: t(inputsParams[key].name),
  //             price: inputsParams[key].price,
  //             isEnabled: inputsParams[key].isEnabled
  //           }
  //         };
  //         return temp;
  //       })
  //     )
  //   },

  //   checkDates: {
  //     totalDays: 1,
  //     personsNumber: 1
  //   },
  //   totalCart: 0
  // });

  // useEffect(() => {
  //   if (
  //     bookingData.choosedOffers.servicePack["lovelyPack"] != null &&
  //     bookingData.checkDates.totalDays < 2 &&
  //     bookingData.checkDates.totalDays >= 1
  //   ) {
  //     setInfoBox(true);
  //   }
  // }, [bookingData.choosedOffers.servicePack, bookingData.checkDates.totalDays]);

  // const dataFetch = (data: InputFormProps) => {
  //   setbookingData({
  //     ...bookingData,
  //     choosedOffers: {
  //       servicePack: data.servicePack,
  //       extrasOptions: data.extrasOptions
  //     }
  //   });
  // };
  // const calendarFetch = (datesIn: DatesProps) => {
  //   setbookingData((bookingData) => ({
  //     ...bookingData,
  //     checkDates: datesIn
  //   }));
  // };
  // const inputDataFetch = (data: InputBookInterface) => {
  //   setbookingData((bookingData) => ({
  //     ...bookingData,
  //     firstName: data.firstName,
  //     lastName: data.lastName,
  //     email: data.dataEmail.emailString,
  //     phoneNo: data.phone,
  //     otherDetails: data.otherDetails
  //   }));
  // };

  // const closeDeskPop = () => {
  //   setDeskPopVisible(false);
  //   setDeskFormStep((desktopFormStep) => ({ ...desktopFormStep, index: 0 }));
  // };

  // const validateBookingData = () => {
  //   ReactGA.event(`user_Button_validateBookingData`);
  //   if (isMobileActive) {
  //     setMobileStep((mobileFormStep) => ({
  //       ...mobileFormStep,
  //       index: 1
  //     }));
  //   } else {
  //     if (desktopFormStep.index === 0) {
  //       setDeskFormStep((desktopFormStep) => ({
  //         ...desktopFormStep,
  //         index: desktopFormStep.index + 1
  //       }));
  //       setDeskPopVisible(true);
  //     } else if (desktopFormStep.index === 1) {
  //       setDeskFormStep((desktopFormStep) => ({
  //         ...desktopFormStep,
  //         index: desktopFormStep.index + 1
  //       }));
  //     }
  //   }
  // };

  // useEffect(() => {
  //   let extrasTotal = 0;
  //   let servicePackPrice = 0;
  //   Object.values(bookingData.choosedOffers.extrasOptions || " ").map((item) => {
  //     if (item.isEnabled) {
  //       extrasTotal += item.price;
  //     }
  //   });

  //   servicePackPrice =
  //     bookingData.choosedOffers.servicePack[Object.keys(bookingData.choosedOffers?.servicePack)[0]]
  //       .price || 0;
  //   const totalCartL = extrasTotal + servicePackPrice;

  //   setbookingData((bookingData) => ({
  //     ...bookingData,
  //     totalCart: totalCartL
  //   }));
  // }, [bookingData.choosedOffers.extrasOptions, bookingData.choosedOffers.servicePack]);

  // const stepMobileHandler = (counter: number) => {
  //   if (isMobileActive) {
  //     setMobileStep((mobileFormStep) => ({
  //       ...mobileFormStep,
  //       index: counter
  //     }));
  //   }
  // };
  // const infoBoxHandler = () => {
  //   setInfoBox(false);
  // };

  // const DesktopHandler = (stepIndex: number) => {
  //   setDeskFormStep((desktopFormStep) => ({
  //     ...desktopFormStep,
  //     index: desktopFormStep.index + stepIndex
  //   }));
  // };

  const layoutStyle = () => {
    if (floating != null && floating) {
      return styles.floatingStyle;
    } else return styles.staticStyle;
  };

  const floatingHandler = () => {
    if (floating != null && !floating) {
      return (
        <div className={styles.genericMobile}>
          <HeadText
            topText={t(bookingComponent.topMessage)}
            bottomText={t(bookingComponent.bottomMessage)}
          />
        </div>
      );
    }
  };

  return (
    <>
      {floatingHandler()}
      <div ref={ref} className={layoutStyle()}>
        {/* <div className={styles.bookingCard}>
        {!isMobileActive ? (
            <>
              {!deskPopVisible ? (
                <>
                  <InputBooking bookingFetch={inputDataFetch} />
                  <DatePickerBox
                    infoBoxIsOn={infoBox}
                    mobileVersion={false}
                    calendarNotification={calendarFetch}
                    isFloating={floating != null ? false : true}
                  />
                  <RadioInputs dataAquired={dataFetch} storeInputs={bookingData.choosedOffers} />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <DatePickerBox mobileVersion={true} calendarNotification={calendarFetch} />
          )}
          {deskPopVisible && desktopFormStep.index === 1 && (
            <ConfirmOverview bookingInputData={bookingData} />
          )}
          {deskPopVisible && desktopFormStep.index === 2 && (
            <BackdropDialog
              indexStep={desktopFormStep.index}
              actionEvent={closeDeskPop}
              stepNotification={DesktopHandler}>
              <PaymentStatus typeOfStatus={"success"} />
            </BackdropDialog>
          )}
          {mobileFormStep.index === 1 ? (
            <StepGuider indexStep={mobileFormStep.index} stepsNotification={stepMobileHandler}>
              <RadioInputs
                informationOffer={
                  componentStrings.bookingComponent.sericePack[
                    Object.keys(bookingData.choosedOffers.servicePack)[0]
                  ].details
                }
                storeInputs={bookingData.choosedOffers}
                bookingTotal={bookingData.totalCart}
                dataAquired={dataFetch}
              />
            </StepGuider>
          ) : mobileFormStep.index === 2 ? (
            <StepGuider indexStep={mobileFormStep.index} stepsNotification={stepMobileHandler}>
              <InputBooking bookingFetch={inputDataFetch} />
            </StepGuider>
          ) : mobileFormStep.index === 3 ? (
            <StepGuider indexStep={mobileFormStep.index} stepsNotification={stepMobileHandler}>
              <ConfirmOverview bookingInputData={bookingData} />
            </StepGuider>
          ) : (
            ""
          )}
          <div className={styles.offerOptionNotification}>
            <p>
              {t(componentStrings.bookingComponent.offerDetailsIncluded.included, {
                packIncludedList: t(
                  componentStrings.bookingComponent.sericePack[
                    Object.keys(bookingData.choosedOffers.servicePack)[0]
                  ].details
                )
              })}
            </p>
          </div>
          <div className={styles.extender}>
            {!deskPopVisible ? (
              <>
                <div className={styles.columnS}>
                  <div className={styles.containerSider}>
                    <p className={styles.bookingOverview}>
                      {` ${bookingData.checkDates.totalDays} ` +
                        " " +
                        ` ${
                          bookingData.checkDates.totalDays === 1
                            ? t(bookingComponent.days.single)
                            : t(bookingComponent.days.more)
                        }  ` +
                        " " +
                        bookingData.checkDates?.personsNumber +
                        " " +
                        ` ${
                          bookingData.checkDates?.personsNumber === 1
                            ? t(bookingComponent.persons.single)
                            : t(bookingComponent.persons.more)
                        } `}
                    </p>
                    <div className={styles.bottomLine} />
                  </div>
                </div>

                <div className={styles.columnM}>
                  <button onClick={validateBookingData} className={styles.bookNowButton}>
                    {t(bookingComponent.bookNowText)}
                  </button>
                </div>
                <div className={styles.columnS}>
                  <div className={styles.containerSider}>
                    <p className={styles.bookingOverview}>
                      {t(bookingComponent.price) +
                        ": " +
                        bookingData?.totalCart +
                        " " +
                        t(bookingComponent.currency)}
                    </p>
                    <div className={styles.bottomLine} />
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.orderActions}>
                <button onClick={validateBookingData} className={styles.confirmButton}>
                  {t(bookingComponent.confirmText)}
                </button>
                <button onClick={closeDeskPop} className={styles.bookCancel}>
                  {t(bookingComponent.cancelBook)}
                </button>
              </div>
            )}
          </div>
          <DesktopGuide indexState={desktopFormStep.index}></DesktopGuide>
          </div> */}

        {closeButton ? (
          <a onClick={() => close()}>
            <img className={styles.closeButton} src={images.closeButton} />
          </a>
        ) : (
          <></>
        )}
        <NewCalendar
          showButton={true}
          title={t(bookingComponent.title)}
          dates={dates}
          mobile={isMobile}
          noMonth={2}
          setDates={setDates}
          disabledDays={disabledDays}
          open={showCheckInCalendar}
        />
      </div>
    </>
  );
};

export default BookingComponent;
