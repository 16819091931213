import React, { Children, cloneElement, useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import ReactGA from "react-ga4";

import styles from "./OffersCarousel.module.scss";
import { componentStrings, OffersDetailsText } from "../data/strings";
import { useTranslation } from "react-i18next";

interface IProps {
  children: JSX.Element[];
  leftArrow: string;
  rightArrow: string;
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
}

interface CarouselItemType {
  children: object;
  width: string;
}

export const CarouselItem: React.FC<CarouselItemType> = ({ children, width }) => {
  return (
    <div className={styles.carouselItem} style={{ width: width }}>
      {children}
    </div>
  );
};

const OffersCarousel: React.FC<IProps> = ({
  children,
  leftArrow,
  rightArrow,
  setActiveIndex,
  activeIndex
}) => {
  const { t } = useTranslation();
  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = Children.count(children) - 1;
      } else if (newIndex >= Children.count(children)) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [children]
  );

  const previousImage = () => {
    updateIndex(activeIndex - 1);
  };

  const nextImage = () => {
    ReactGA.event("user_MainCarousel_swipeButton");
    updateIndex(activeIndex + 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      nextImage();
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        previousImage();
      }
    }
  });

  return (
    <div {...handlers} className={styles.carousel}>
      <div
        className={styles.inner}
        style={{
          transform: `translateX(${
            activeIndex === 0 ? 12.5 : -(activeIndex === 1 ? 87.5 : 187.5)
          }%)`
        }}>
        {Children.map(children, (child: JSX.Element) => {
          return cloneElement(child, { width: "100%" });
        })}
      </div>
      {activeIndex > 0 ? (
        <img onClick={previousImage} src={leftArrow} className={styles.leftArrow} />
      ) : (
        <></>
      )}
      {activeIndex === Children.count(children) - 1 ? (
        <></>
      ) : (
        <img onClick={nextImage} src={rightArrow} className={styles.rightArrow} />
      )}
      <div className={styles.underText}>
        {t(componentStrings.OffersCards.pricesInfo, {
          monday: t(OffersDetailsText.monday),
          thursday: t(OffersDetailsText.thursday),
          friday: t(OffersDetailsText.friday),
          sunday: t(OffersDetailsText.sunday),
          relaxPackPrice: OffersDetailsText.relaxPackPrice,
          relaxPackPriceWeekend: OffersDetailsText.relaxPackPriceWeekend,
          lovelyPackPrice: OffersDetailsText.lovelyPackPrice,
          lovelyPackPriceWeekend: OffersDetailsText.lovelyPackPriceWeekend,
          romanticPackPrice: OffersDetailsText.romanticPackPrice,
          romanticPackPriceWeekend: OffersDetailsText.romanticPackPriceWeekend
        })}
      </div>
    </div>
  );
};

export default OffersCarousel;
